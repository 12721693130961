import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import PublishIcon from "@material-ui/icons/Publish";
import { toAbsoluteUrl } from "../../../../_metronic";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default class BillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loadingSave: 0,
      limit: 25,
      activePage: 1,
      dataCount: 0,
      errorMsg: "",
      status: null,
      data: [],
    };
  }
  openSidebarModal2 = (modalData = null) => {
    this.setState({ openModal: !this.state.openModal, modalData });
  };
  closeSidebarModal2 = () => {
    this.setState({ openModal: false, modalData: null }, () => {});
  };
  noteModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3"></div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getData();
    });
  };
  getData = async () => {
    try {
      this.setState({ loading: 1 });
      const body = JSON.stringify({
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        type: "getBillsDeitelsBillId",
        page: this.state.activePage,
        limit: this.state.limit,
        getType: "limit",
        billId: this.props.billId,
      });
      const response = await fetch(window.API_PERM_USER, {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data.error == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            this.setState({
              data: data.data,
              dataCount: data.count,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ errorMsg: error.message, data: [], activePage: 1 });
    } finally {
      this.setState({ loading: 0 });
    }
  };
  componentDidMount = () => {
    this.getData();
  };
  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["details"]}
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.close}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
          {this.state.loading == 0 && this.state.errorMsg != "" && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">{this.state.errorMsg}</div>
                </div>
              </div>
            </Row>
          )}
          {this.state.loading == 0 &&
            this.state.data.length <= 0 &&
            this.state.errorMsg == "" && (
              <Row>
                <div className="inv-cards-view pb-5 width-100">
                  <div
                    className="text-center"
                    style={{ marginTop: "15%", marginBottom: "15%" }}
                  >
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/ezpay/EZPAY_LOGO_GRAY" +
                          window.MAIN_IMAGE +
                          ".png"
                      )}
                      className="img-fluid no-data-img"
                    />
                    <div className="no-data-text">
                      {Strings["There is no data to display"]}
                    </div>
                  </div>
                </div>
              </Row>
            )}
          {this.state.loading == 1 && (
            <Row>
              <Col lg={12} style={{ marginTop: "7%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading == 0 && this.state.data.length > 0 && (
            <Row>
              <div className="inv-cards-view width-100">
                <Row className="table-header-row d-none d-lg-flex d-md-flex">
                  <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Tran ID"]}
                  >
                    {Strings["Tran ID"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Notes"]}
                  >
                    {Strings["Notes"]}
                  </Col>
                  <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["confirmDate"]}
                  >
                    {Strings["confirmDate"]}
                  </Col>

                  <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Amount"]}
                  >
                    {Strings["Amount"]}
                  </Col>

                  {/* <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["remainingAmount2"]}
                  >
                    {Strings["remainingAmount2"]}
                  </Col> */}
                  <Col
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["payAmount"]}
                  >
                    {Strings["payAmount"]}
                  </Col>
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["State"]}
                  >
                    {Strings["State"]}
                  </Col>
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Image"]}
                  >
                    {Strings["Image"]}
                  </Col>
                </Row>
                {this.state.data.map((rowData, index) => (
                  <Row key={index} className="table-body-row">
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["Tran ID"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Tran ID"]} :{" "}
                      </span>
                      {rowData.payId}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["Notes"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Notes"]} :{" "}
                      </span>
                      {rowData.notes?.length > 0 && (
                        <a
                          className="link-color-sample"
                          onClick={(e) => {
                            e.preventDefault();
                            this.noteModal(rowData.notes);
                          }}
                        >
                          {Strings["View Details"]}
                        </a>
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["confirmDate"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["confirmDate"]} :{" "}
                      </span>
                      {rowData.confirmDate}
                    </Col>

                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["Amount"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Amount"]} :{" "}
                      </span>
                      {rowData.amount}
                    </Col>
                    {/* <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["remainingAmount2"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["remainingAmount2"]} :{" "}
                      </span>
                      {rowData.remainingAmount}
                    </Col> */}
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["payAmount"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["payAmount"]} :{" "}
                      </span>
                      {rowData.payAmount}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["State"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["State"]} :{" "}
                      </span>
                      {rowData.status}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length "
                      title={Strings["Image"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Image"]} :{" "}
                      </span>
                      {rowData.paymentImg ? (
                        <a href={rowData.paymentImg} target="_blank">
                          {Strings["clickHere"]}
                        </a>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </Row>
          )}
          {this.state.loading == 0 && this.state.data?.length > 0 && (
            <Col md={12}>
              <Row className="pagenation-view mt-3 mb-4">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.limit}
                  totalItemsCount={this.state.dataCount}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange}
                  prevPageText={
                    <i
                      className={
                        localStorage.getItem("LANG") == 1
                          ? "flaticon2-next"
                          : "flaticon2-back"
                      }
                    ></i>
                  }
                  nextPageText={
                    <i
                      className={
                        localStorage.getItem("LANG") == 1
                          ? "flaticon2-back"
                          : "flaticon2-next"
                      }
                    ></i>
                  }
                  hideFirstLastPages
                  innerClass={
                    "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                  }
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                />
              </Row>
            </Col>
          )}
        </div>
      </div>
    );
  }
}
