import React, { Component } from "react";
import {
  UncontrolledDropdown,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
// import Link from '@material-ui/core/Link';
import { Link, withRouter } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import { Drawer, Button } from "@material-ui/core";
import EmpForm from "./forms/empForm";
import PosFinancialReport from "./forms/posFinancialReport";
import AddOrGetAmount from "./forms/addOrGetAmount";
import AccountStatement from "./forms/accountStatement";
import ViewAppTrans from "./forms/viewAppTrans";
import { toAbsoluteUrl } from "../../../_metronic";
import { translations } from "../../../translate.js";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "rc-tooltip";
import { CircularProgress } from "@material-ui/core";
import "rc-tooltip/assets/bootstrap.css";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import ViewAppVersion from "./forms/ViewAppVersion.js";
import PosLimits from "./forms/PosLimits.jsx";
import DebitStatus from "../DebitStatus/DebitStatus.jsx";
import ReceivablesFlow from "../ReceivablesFlow/ReceivablesFlow.jsx";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class PosEmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSave: 0,
      limit: 25,
      activePage: 1,
      dataCount: 0,
      openHeader: "",
      viewMode: "",
      sortBy: 0,
      empData: [],
      distClassList: [],
      distClassListSelected: [],
      openleftModal: false,
      data: [],
      openPosAction: false,
      actionType: "",
      posData: [],
      errorMsg: "",
      mangerPos: [],
      totalBalancePos: 0,
      notActivePos: 0,
      activePos: 0,
      selectedMangerPos: [],
      selectedAcountTypes: [],
      allCkecked: 1,
      posManagerName: "",
      sortColNum: 0,
      selectedSortType: [],
      statusTypes: [
        {
          title: Strings["All"],
          value: "",
        },
        {
          title: Strings["Active Pos"],
          value: 2,
        },
        {
          title: Strings["Not Active Pos"],
          value: 1,
        },
      ],
      selectedStatusType: null,
      sortName: "",
      mainClassHeader:
        localStorage.getItem("POS_MGR_EMP") == 1 ||
        localStorage.getItem("ACC_EMP") == 1
          ? "table-header-row  text-center"
          : "table-header-row ",
      mainClassBody:
        localStorage.getItem("POS_MGR_EMP") == 1 ||
        localStorage.getItem("ACC_EMP") == 1
          ? "table-body-row  text-center"
          : "table-body-row",
      pageView: this.props.match.params.pageType,
      stringActivePos: Strings["Active Pos"],
      stringNotActivePos: Strings["Not Active Pos"],
      stringTotal:
        this.props.match.params.pageType != undefined &&
        this.props.match.params.pageType == 1
          ? Strings["Total balances of POS managers"]
          : Strings["Total POS Balances"],
      accountTypes: [],
      distId: this.props?.location?.state?.distId,
      distData: this.props?.location?.state?.distData,
      permType: this.props?.location?.state?.distData?.permType,
      isTaxDist: this.props?.location?.state?.distData?.isTaxDist,
      pageNameFlag: this.props?.location?.state?.flag,
    };
    // console.log("-------page type---------");
    // console.log(this.props.match.params.pageType);
  }
  handleRefresh = () => {
    // clear all filter data
    this.setState(
      {
        searchKey: "",
        selectedMangerPos: [],
        selectedAcountTypes: [],
        distClassListSelected: [],
      },
      function() {
        this.getPos();
      }
    );
  };
  componentDidMount() {
    console.log("this.state.pageView", this.state.pageView);

    this.getPos();
    document.addEventListener("mousedown", this.handleClickOutside);
    if (
      (localStorage.getItem("ACC_EMP") == 1 ||
        localStorage.getItem("PERM_TYPE") == 5 ||
        this.state.permType == 5 ||
        localStorage.getItem("OPERA_EMP") == 1) &&
      this.state.pageView != 1
    ) {
      this.getPos(8, 1);
      this.getDistClasses();
    }
    if (this.state.pageView == 1) {
      this.getAcountTypes();
    }
    this.setColResponsive();
    // alert(this.props.match.params.pageType)
    // console.log("-------componentDidMount---------");
    // console.log(this.props.match.params.pageType);
  }
  exportHandler() {
    const {
      dateFrom,
      dateTo,
      dateType,
      number,
      balanceType,
      selectedStatus,
      selectedSupplier,
    } = this.state;
    const status = Array.isArray(selectedStatus)
      ? selectedStatus.map((data) => data.id_react).join(",")
      : "";
    const selectType = Array.isArray(selectedSupplier)
      ? selectedSupplier.map((data) => data.id).join(",")
      : "";
    const queryParams =
      `type=getDistPos&lang=${encodeURIComponent(
        localStorage.getItem("LANG")
      )}&userId=${encodeURIComponent(
        localStorage.getItem("USER_ID")
      )}&token=${encodeURIComponent(
        localStorage.getItem("USER_TOKEN")
      )}&export=1` +
      `&dateFrom=${encodeURIComponent(dateFrom || "")}` +
      `&dateTo=${encodeURIComponent(dateTo || "")}` +
      `&typeDate=${encodeURIComponent(dateType || "")}` +
      `&number=${encodeURIComponent(number || "")}` +
      `&balanceType=${encodeURIComponent(balanceType || "")}` +
      `&status=${encodeURIComponent(status)}` +
      `&selectType=${encodeURIComponent(
        selectType
      )}&distId=${encodeURIComponent(
        this.state.distId ? this.state.distId : ""
      )}&userStatus=${encodeURIComponent(
        this.state.selectedStatusType ? this.state.selectedStatusType.value : ""
      )}`;
    window.location = `${window.API_PERM_USER}/permUser.php?${queryParams}`;
  }
  componentDidUpdate(prevProps) {
    //must be ask alaa
    if (this.props.match.params.pageType !== prevProps.match.params.pageType) {
      this.setState(
        {
          pageView: this.props.match.params.pageType,
          stringTotal:
            this.props.match.params.pageType != undefined &&
            this.props.match.params.pageType == 1
              ? Strings["Total balances of POS managers"]
              : Strings["Total POS Balances"],
        },
        function() {
          this.constructor(this.props);
          this.componentDidMount();
        }
      );
    }
  }
  setColResponsive = () => {
    this.setState({
      colPosName: 2,
      colJoinDate: 1,
      colPhone: 1,
      colEmail: 2,
      colBal: 1,
    });
  };
  getAcountTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getAcountTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              accountTypes: data["data"],
            });
          }
        }
      });
  }
  getPos(accountType = 0, getOnlyPosAdminList = 0, loading = 1) {
    var allPos = 1;
    var pageType = this.props.match.params.pageType;
    var mangerPos = [];
    var distClass = [];
    if (
      pageType == 0 ||
      localStorage.getItem("PERM_TYPE") == 5 ||
      this.state.permType == 5 ||
      localStorage.getItem("POS_MGR_EMP") == 1 ||
      localStorage.getItem("ACC_EMP") == 1 ||
      localStorage.getItem("OPERA_EMP") == 1 ||
      localStorage.getItem("USER_LEVEL") == 18
    ) {
      allPos = 0;
    }
    if (pageType == 1) {
      accountType = this.state.selectedAcountTypes.map(
        (data) => data.user_type
      );
    }
    if (
      pageType == undefined &&
      (localStorage.getItem("PERM_TYPE") == 5 ||
        this.state.permType == 5 ||
        localStorage.getItem("ACC_EMP") == 1 ||
        localStorage.getItem("OPERA_EMP") == 1) &&
      getOnlyPosAdminList == 0
    ) {
      mangerPos = this.state.selectedMangerPos.map((data) => data.userId);
      distClass = this.state.distClassListSelected.map((data) => data.id);
    }
    if (getOnlyPosAdminList == 0 && loading == 1) {
      this.setState({ loading: 1 });
    }
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        allPos: getOnlyPosAdminList == 1 ? "" : allPos,
        getType: getOnlyPosAdminList == 1 ? "all" : "limit",
        page: this.state.activePage,
        limit: this.state.limit,
        searchKey: getOnlyPosAdminList == 1 ? "" : this.state.searchKey,
        accountType: accountType,
        posParentId: mangerPos,
        priceCatId: distClass,
        sort: this.state.sortBy,
        sortBy: this.state.sortName,
        allEmp: this.state.pageView,
        distId: this.state.distId ? this.state.distId : "",
        userStatus: this.state.selectedStatusType
          ? this.state.selectedStatusType.value
          : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else {
            if (getOnlyPosAdminList == 0) {
              this.setState({
                data: data["data"],
                dataCount: data["dataCount"],
                loading: 0,
                sortBy: 0,
                totalBalancePos: parseFloat(data["totalBalancePos"] || 0),
                activePos: parseInt(data["activePos"] || 0, 10),
                notActivePos: parseInt(data["notActivePos"] || 0, 10),
                totalPosCount: data.countAllPos,
              });
            } else {
              this.setState({
                mangerPos: data["data"],
              });
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching POS data:", error);
        this.setState({ loading: 0, errorMsg: "Failed to fetch data" });
      });
  }
  getDistClasses() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistClasses",
        distId: this.state.distId ? this.state.distId : "",
        list: localStorage.getItem("USER_LEVEL") == 1 ? 1 : "",

        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              distClassList: data["data"],
            });
          }
        }
      });
  }
  handleClickOutside = (event) => {
    if (event.target.className != undefined) {
      if (!event.target.classList.contains("actionDropdownList")) {
        this.setState({
          openHeader: "",
          visibleid: "",
        });
      }
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getPos();
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        this.getPos();
      }
    );
  };

  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };
  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  handleChangeStatus = (event, type, id) => {
    var checked = event.target.checked == true ? 1 : 0;

    if (type == "cardStatus") {
      var name = "cardStatus-" + id;
      this.setState({ [name]: event.target.checked }, function() {
        this.updatePosCardStatus(checked, id);
      });
    } else if (type == "taxPos") {
      var name = "taxPos-" + id;
      this.setState({ [name]: event.target.checked }, function() {
        this.updatePosTaxStatus(checked, id);
      });
    } else {
      var name = "distStatus-" + id;
      this.setState({ [name]: event.target.checked }, function() {
        this.updatePosStatus(checked, id);
      });
    }
  };

  updatePosStatus(checked, posId) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePosStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        checkVal: checked,
        posId: this.state.pageView != 1 ? posId : 0,
        empId: this.state.pageView == 1 ? posId : 0, //just used on employee case in distributor pos type
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0) {
            this.alertErrorSubmite(data["errorMsg"]);
          } else {
            this.getPos();
          }
        }
      });
  }

  updatePosCardStatus(checked, posId) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePosCardStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        checkVal: checked,
        posId: posId,
        distId: this.state.distId ? this.state.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getPos();
          }
        }
      });
  }

  updatePosTaxStatus(checked, posId) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "updatePosTaxStatus",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        checkVal: checked,
        distId: this.state.distId ? this.state.distId : "",
        posId: posId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.getPos();
          }
        }
      });
  }

  getPosManagerInfo = (posParentId, supervisorPosId) => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getEmpInfo",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        distId: this.state.distId ? this.state.distId : "",
        token: localStorage.getItem("USER_TOKEN"),
        empId: posParentId,
        supervisorPosId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ posManagerName: data["fulName"], loadingTips: 0 });
          }
        }
      });
  };

  openSidebarModal = (event, type, data) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        empData: data,
        status: type,
        openleftModal: true,
      },
      function() {
        // document.removeEventListener('click', this.openHeaderMenu, false);
      }
    );
  };

  handleCloseSideBar = () => {
    this.setState({ openleftModal: false, empData: [], status: "" });
  };

  handleFormAction = () => {
    this.setState(
      {
        empData: [],
        status: "",
        openleftModal: false,
      },
      function() {
        this.getPos();
      }
    );
  };

  openPosAction = (event, type, data, viewMode) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        posData: data,
        viewMode: viewMode,
        actionType: type,
        openPosAction: true,
      },
      function() {}
    );
  };

  handleClosePosAction = () => {
    this.setState({ posData: [], actionType: "", openPosAction: false });
  };

  resetAuthentication = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to  Reset Authentication ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickResetAuthentication(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  alertActivateAllCards = (event, check) => {
    var msg =
      check == 1
        ? Strings["Are you want to activate all cards for all POS ?"]
        : Strings["Are you want to deactivate all cards for all POS ?"];
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{msg}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.activateAllPosCards(check);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  handleClickResetAuthentication(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "resetAuth",
        distId: this.state.distId ? this.state.distId : "",
        posId: this.state.pageView != 1 ? id : 0,
        empId: this.state.pageView == 1 ? id : 0, //just used on employee case in distributor pos type
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0) {
            this.alertErrorSubmite(data["errorMsg"]);
          }
        }
      });
  }

  deletePos = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        var str =
          this.state.pageView == 1
            ? Strings["You want to delete this employee ?"]
            : Strings["You want to delete this pos ?"];
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{str}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete(id) {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "deletePos",
        distId: this.state.distId ? this.state.distId : "",
        posId: this.state.pageView != 1 ? id : 0,
        empId: this.state.pageView == 1 ? id : 0, //just used on employee case in distributor pos type
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0) {
            this.alertErrorSubmite(data["errorMsg"]);
          } else {
            this.getPos();
          }
        }
      });
  }
  alertErrorSubmite = (errorMsg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view ">
            <div className="mb-4">
              <HighlightOffIcon className="error-alert-icon" size={70} />
            </div>
            <p className="mb-3 ">{errorMsg}</p>
            <button
              type="button"
              className="btn  alert-btn-margin ml-2 mr-2 filter-empty-email label-error"
              onClick={onClose}
            >
              {Strings.Close}
            </button>
          </div>
        );
      },
    });
  };
  recallGetData = () => {
    this.getPos(0, 0, 0);
  };
  handleChangeSelect = (type, option) => {
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
          msgError: 0,
        };
      },
      function() {
        this.getPos();
      }
    );
  };
  onVisibleChange = (visible, id, type, posParentId, supervisorPosId) => {
    this.setState(
      {
        [type]: id,
        posManagerName: "",
        loadingTips: 1,
      },
      function() {
        this.getPosManagerInfo(posParentId, supervisorPosId);
      }
    );
  };
  activateAllPosCards(checked) {
    this.setState({ loadingSave: 1, loading: 1 });
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "activateAllPosCards",
        lang: localStorage.getItem("LANG"),
        distId: this.state.distId ? this.state.distId : "",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        posMgrId:
          this.state.selectedMangerPos.length > 0
            ? this.state.selectedMangerPos.map((data) => data.userId)
            : "",
        checkStatus: checked,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ msgError: data["error"] });
          } else {
            this.setState(
              {
                allCkecked: !this.state.allCkecked,
                activePage: 1,
                loadingSave: 0,
              },
              function() {
                this.getPos();
              }
            );
          }
        }
      });
  }
  handleActivateAllCard = (event) => {
    const value = event.target.value;
    this.alertActivateAllCards(event, value);
  };
  sortCoulmn = (colNum, name) => {
    // console.log(colNum,name);

    this.setState({ sortColNum: colNum }, function() {
      if (
        (this.state.selectedSortType[colNum] == "" ||
          this.state.selectedSortType[colNum] == undefined ||
          this.state.selectedSortType[colNum] == null) &&
        this.state.selectedSortType[colNum] != 0
      ) {
        var sortVal = 2; // desc
      } else if (this.state.selectedSortType[colNum] == 1) {
        var sortVal = 2; // desc
      } else {
        var sortVal = 1; // asc
      }

      const newArray = this.state.selectedSortType;
      newArray[colNum] = sortVal;
      this.setState(
        {
          selectedSortType: newArray,
          activePage: 1,
          sortName: name,
          sortBy: sortVal,
        },
        function() {
          this.getPos();
        }
      );
    });
  };
  sortIcon = (colNum) => {
    return this.state.sortColNum == colNum ? (
      this.state.selectedSortType[colNum] == 2 ? (
        <SortAscendingIcon size={13} />
      ) : (
        <SortDescendingIcon size={13} />
      )
    ) : (
      <SortAscendingIcon className="not-active-sort" size={13} />
    );
  };
  displayData() {
    //&& localStorage.getItem('PERM_TYPE') != 5
    var orderInfo = "";
    var balance = "";
    var classStyle = {
      "text-1": "reject-color",
      "text-2": "warning-color",
      "text-8": "succ-color",
      "text-17": "status-10-color",
      "text-18": "status-9-color",
    };
    var acctype;
    var classColStatus = "";
    // var colJoinDate = (localStorage.getItem('PERM_TYPE') == 5) ? 1 : 2;
    if (this.state.loading == 0 && this.state.data.length > 0) {
      orderInfo = this.state.data.map((rowData, index) => {
        rowData["distBalanceAmountUserCurr"] =
          rowData["distBalanceAmountUserCurr"] == undefined ||
          rowData["distBalanceAmountUserCurr"] == ""
            ? 0
            : rowData["distBalanceAmountUserCurr"];
        balance =
          rowData["accountType"] == 7 ||
          rowData["accountType"] == 8 ||
          rowData["accountType"] == 17 ||
          rowData["accountType"] == 18
            ? rowData["distBalanceAmountUserCurr"]
            : "-";
        acctype = rowData["accountType"];
        if (acctype !== "") {
          classColStatus = classStyle["text-" + acctype];
        }
        classColStatus =
          " overflow-text-length  bold-font " + classColStatus + " ";
        return (
          <Row key={rowData.userId} className={this.state.mainClassBody}>
            <Col
              lg={this.state.colPosName}
              md={this.state.colPosName}
              sm={12}
              xs={12}
              className="overflow-text-length"
              title={rowData["fname"] + "" + rowData["lname"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Name"]} :{" "}
              </span>
              {rowData["fname"]} {rowData["lname"]}
            </Col>
            <Col
              lg={this.state.colJoinDate}
              md={this.state.colJoinDate}
              sm={12}
              xs={12}
              className="overflow-text-length"
              title={rowData["joinDate"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Subscription Date"]} :{" "}
              </span>
              {rowData["joinDate"]}
            </Col>
            {(localStorage.getItem("ACC_EMP") == 1 ||
              localStorage.getItem("OPERA_EMP") == 1 ||
              this.state.permType == 5 ||
              localStorage.getItem("PERM_TYPE") == 5) &&
              this.state.pageView != 1 && (
                // <Col lg={1} md={3} sm={4} xs={12} className="overflow-text-length " title={rowData['posMangerName']}> {rowData['posMangerName']}</Col>
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length "
                  title={rowData["POS Manager"]}
                >
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["POS Manager"]} :{" "}
                  </span>
                  <div>
                    {(rowData["posParentId"] != "" &&
                      rowData["posParentId"] != 0) ||
                    (rowData["supervisorPosId"] != "" &&
                      rowData["supervisorPosId"] != 0) ? (
                      <Tooltip
                        title={Strings["responseData"]}
                        visible={
                          this.state.visibleid == rowData["userId"] &&
                          this.state.loadingTips == 0
                            ? true
                            : false
                        }
                        animation="zoom"
                        onVisibleChange={(event) =>
                          this.onVisibleChange(
                            event,
                            rowData["userId"],
                            "visibleid",
                            rowData["posParentId"],
                            rowData.supervisorPosId
                          )
                        }
                        trigger="click"
                        overlay={
                          <span className="report-tooltip-span">
                            <p className="mb-0 mr-1 tooltipRequestStyle break-words">
                              {this.state.posManagerName
                                ?.split("\\n")
                                ?.join("\n")}
                            </p>
                          </span>
                        }
                      >
                        {this.state.loadingTips == 1 &&
                        this.state.visibleid == rowData["userId"] ? (
                          <CircularProgress className="CircularProgressColor hover" />
                        ) : (
                          <InfoOutlinedIcon
                            title={Strings["POS Manager"]}
                            className="reset-filter-btn mt-1 mb-1 hover-green"
                          />
                        )}
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
              )}
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.state.permType == 5 ||
              localStorage.getItem("POS_MGR_EMP") == 1 ||
              localStorage.getItem("ACC_EMP") == 1 ||
              localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
              localStorage.getItem("USER_LEVEL") == 18 ||
              localStorage.getItem("OPERA_EMP") == 1) && (
              <Col
                lg={this.state.colBal}
                md={this.state.colBal}
                sm={12}
                xs={12}
                className="overflow-text-length"
                title={balance}
              >
                {" "}
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Balance"]} :{" "}
                </span>
                {balance}
              </Col>
            )}
            <Col
              lg={this.state.colPhone}
              md={this.state.colPhone}
              sm={12}
              xs={12}
              className="overflow-text-length"
              title={rowData["mobile"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Phone"]} :{" "}
              </span>
              {rowData["mobile"]}
            </Col>
            <Col
              lg={this.state.colEmail}
              md={this.state.colEmail}
              sm={12}
              xs={12}
              className="overflow-text-length  "
              title={rowData["userEmail"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Email"]} :{" "}
              </span>
              {rowData["userEmail"]}
            </Col>
            <Col
              lg={1}
              md={1}
              sm={12}
              xs={12}
              className="overflow-text-length"
              title={rowData["address"]}
            >
              <span className="d-inline d-lg-none d-md-none text-bold">
                {Strings["Region"]} :{" "}
              </span>
              {rowData["address"]}
            </Col>
            {this.state.pageView != 1 && (
              <Col
                lg={1}
                md={1}
                sm={12}
                xs={12}
                className="overflow-text-length"
                title={rowData["catName"]}
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Category"]} :{" "}
                </span>
                {rowData["catName"]}
              </Col>
            )}
            {this.state.pageView == 1 &&
              (localStorage.getItem("USER_LEVEL") == 1 ||
                (localStorage.getItem("PERM_TYPE") == 5 &&
                  localStorage.getItem("OPEN_BILLS") == 1)) && (
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className={"overflow-text-length"}
                  title={rowData["debitUserCurr"]}
                >
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["Debt Value"]} :{" "}
                  </span>
                  {rowData["debitUserCurr"] ? rowData["debitUserCurr"] : "--"}
                </Col>
              )}
            {this.state.pageView == 1 &&
              (localStorage.getItem("USER_LEVEL") == 1 ||
                (localStorage.getItem("PERM_TYPE") == 5 &&
                  localStorage.getItem("OPEN_BILLS") == 1)) && (
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className={"overflow-text-length"}
                  title={rowData["debitUserCurr"]}
                >
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["Receivables Value"]} :{" "}
                  </span>
                  {rowData["receivablesUserCurr"]
                    ? rowData["receivablesUserCurr"]
                    : "--"}
                </Col>
              )}
            {this.state.pageView == 1 && (
              <Col
                lg={1}
                md={1}
                sm={12}
                xs={12}
                className={classColStatus}
                title={
                  localStorage.getItem("LANG") == 1
                    ? rowData["accountTypeName"]["name_ar"]
                    : rowData["accountTypeName"]["name_en"]
                }
              >
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Acount Type"]} :{" "}
                </span>
                {localStorage.getItem("LANG") == 1
                  ? rowData["accountTypeName"]["name_ar"]
                  : rowData["accountTypeName"]["name_en"]}
              </Col>
            )}
            {/* {this.state.pageView != 1 &&
              localStorage.getItem("POS_MGR_EMP") == 0 &&
              localStorage.getItem("ACC_EMP") == 0 && (
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length "
                >
                  <div>
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["All Cards"]} :{" "}
                  </span>
                  <Switch
                    checked={rowData["cardStatus"] == 1 ? true : false}
                    onChange={(event) =>
                      this.handleChangeStatus(
                        event,
                        "cardStatus",
                        rowData["userId"]
                      )
                    }
                    name={"cardStatus-" + rowData["userId"]}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    size={"small"}
                  />
                  </div>
                </Col>
              )} */}
            {/* {((this.state.pageView != 1 &&
              localStorage.getItem("IS_TAXDIST") == 1 &&
              localStorage.getItem("USER_LEVEL") == 2 &&
              localStorage.getItem("PERM_TYPE") == 5) ||
              (this.state.isTaxDist == 1 &&
                this.state.permType == 5 &&
                this.state.pageView != 1)) && (
              <Col
                lg={1}
                md={1}
                sm={12}
                xs={12}
                className="overflow-text-length "
              >
               {((this.state.pageView != 1 &&
              localStorage.getItem("IS_TAXDIST") == 1 &&
              localStorage.getItem("USER_LEVEL") == 2 &&
              localStorage.getItem("PERM_TYPE") == 5) ||
              (this.state.isTaxDist == 1 &&
                this.state.permType == 5 &&
                this.state.pageView != 1)) &&  (<div className="">
                <span className="d-inline d-lg-none d-md-none text-bold">
                  {Strings["Tax"]} :{" "}
                </span>
                <Switch
                  checked={rowData["isTaxDist"] == 1 ? true : false}
                  onChange={(event) =>
                    this.handleChangeStatus(event, "taxPos", rowData["userId"])
                  }
                  name={"taxPos-" + rowData["userId"]}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  size={"small"}
                />
                </div>)}
                
              </Col>
            )} */}
            {/* {localStorage.getItem("POS_MGR_EMP") == 0 &&
              localStorage.getItem("ACC_EMP") == 0 && (
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length "
                >
                 <div className="">
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["Status"]} :{" "}
                  </span>
                  <Switch
                    checked={rowData["distStatus"] == 1 ? true : false}
                    onChange={(event) =>
                      this.handleChangeStatus(
                        event,
                        "distStatus",
                        rowData["userId"]
                      )
                    }
                    name={"distStatus-" + rowData["userId"]}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    size={"small"}
                  />
                  </div>
                </Col>
              )} */}

            <Col
              lg={this.state.pageView != 1 ? 2 : 1}
              md={this.state.pageView != 1 ? 2 : 1}
              sm={12}
              xs={12}
              className={`action-icon-view actionDropdownList actionDropdownListPadding-0 order-1 order-md-12 order-lg-12 d-flex  flex-column flex-lg-row flex-md-row justify-content-between`}
            >
              {((this.state.pageView != 1 &&
                localStorage.getItem("IS_TAXDIST") == 1 &&
                localStorage.getItem("USER_LEVEL") == 2 &&
                localStorage.getItem("PERM_TYPE") == 5) ||
                (this.state.isTaxDist == 1 &&
                  this.state.permType == 5 &&
                  this.state.pageView != 1)) && (
                <div className="">
                  <span className="d-inline d-lg-none d-md-none text-bold">
                    {Strings["Tax"]} :{" "}
                  </span>
                  <Switch
                    checked={rowData["isTaxDist"] == 1 ? true : false}
                    onChange={(event) =>
                      this.handleChangeStatus(
                        event,
                        "taxPos",
                        rowData["userId"]
                      )
                    }
                    name={"taxPos-" + rowData["userId"]}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    size={"small"}
                  />
                </div>
              )}
              {this.state.pageView != 1 &&
                localStorage.getItem("POS_MGR_EMP") == 0 &&
                localStorage.getItem("ACC_EMP") == 0 && (
                  <div className="">
                    <span className="d-inline d-lg-none d-md-none text-bold">
                      {Strings["All Cards"]} :{" "}
                    </span>
                    <Switch
                      checked={rowData["cardStatus"] == 1 ? true : false}
                      onChange={(event) =>
                        this.handleChangeStatus(
                          event,
                          "cardStatus",
                          rowData["userId"]
                        )
                      }
                      name={"cardStatus-" + rowData["userId"]}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      size={"small"}
                    />
                  </div>
                )}
              {localStorage.getItem("POS_MGR_EMP") == 0 &&
                localStorage.getItem("ACC_EMP") == 0 && (
                  <div className="">
                    <span className="d-inline d-lg-none d-md-none text-bold">
                      {Strings["Status"]} :{" "}
                    </span>
                    <Switch
                      checked={rowData["distStatus"] == 1 ? true : false}
                      onChange={(event) =>
                        this.handleChangeStatus(
                          event,
                          "distStatus",
                          rowData["userId"]
                        )
                      }
                      name={"distStatus-" + rowData["userId"]}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      size={"small"}
                    />
                  </div>
                )}

              <Dropdown
                isOpen={
                  this.state.openHeader == rowData["userId"] ? true : false
                }
                className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist d-flex justify-content-end"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                  caret
                >
                  <MoreVertIcon
                    className="menu-dots-vertical"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["userId"])
                    }
                  />
                </DropdownToggle>

                <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    {this.state.pageView != 1 &&
                      (localStorage.getItem("POS_MGR_EMP") == 0 ||
                        localStorage.getItem("ACC_EMP") == 1 ||
                        localStorage.getItem("OPERA_EMP") == 1) &&
                      localStorage.getItem("ACCOUNT_TYPE") != 17 && (
                        <DropdownItem
                          className="actionDropdownList"
                          tag="div"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/inventory/2/" + rowData["userId"],
                              state: {
                                distId: this.state.distId,
                                distData: this.state.distData,
                                flag: this.state.distId ? true : false,
                              },
                            });
                          }}
                          // href={"/inventory/2/" + rowData["userId"]}
                        >
                          {Strings["Cards"]}
                        </DropdownItem>
                      )}
                    {(this.state.pageView != 1 ||
                      rowData["accountType"] == 8 ||
                      rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18) &&
                      (localStorage.getItem("PERM_TYPE") == 5 ||
                        localStorage.getItem("USER_LEVEL") == 18 ||
                        this.state.permType == 5 ||
                        localStorage.getItem("POS_MGR_EMP") == 1 ||
                        localStorage.getItem("ACC_EMP") == 1 ||
                        localStorage.getItem("OPERA_EMP") == 1) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(
                              event,
                              "financial-report",
                              rowData,
                              "x-large"
                            )
                          }
                        >
                          {Strings["Financial Report"]}
                        </DropdownItem>
                      )}
                    {(rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18) &&
                      ((localStorage.getItem("OPEN_BILLS") == 1 &&
                        localStorage.getItem("PERM_TYPE") == 5) ||
                        this.state.permType == 5) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(
                              event,
                              "debitStatus",
                              rowData,
                              "x-large"
                            )
                          }
                        >
                          {Strings["debitFlow"]}
                        </DropdownItem>
                      )}
                    {(rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18) &&
                      ((localStorage.getItem("OPEN_BILLS") == 1 &&
                        localStorage.getItem("PERM_TYPE") == 5) ||
                        this.state.permType == 5) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(
                              event,
                              "ReceivablesFlow",
                              rowData,
                              "x-large"
                            )
                          }
                        >
                          {Strings["ReceivablesFlow"]}
                        </DropdownItem>
                      )}
                    {(this.state.pageView != 1 ||
                      rowData["accountType"] == 8 ||
                      rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18) &&
                      (localStorage.getItem("PERM_TYPE") == 5 ||
                        localStorage.getItem("USER_LEVEL") == 18 ||
                        this.state.permType == 5 ||
                        localStorage.getItem("POS_MGR_EMP") == 1 ||
                        localStorage.getItem("ACC_EMP") == 1 ||
                        localStorage.getItem("OPERA_EMP") == 1) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(
                              event,
                              "account-statement",
                              rowData,
                              "large"
                            )
                          }
                        >
                          {Strings["Account Statement"]}
                        </DropdownItem>
                      )}
                    {(rowData["accountType"] == 7 ||
                      rowData["accountType"] == 8 ||
                      rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18) &&
                      localStorage.getItem("ACC_EMP") == 0 &&
                      localStorage.getItem("POS_MGR_EMP_NEW") != 1 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(event, "add_get_amount", rowData)
                          }
                        >
                          {localStorage.getItem("POS_MGR_EMP") == 1 ||
                          localStorage.getItem("OPERA_EMP") == 1 ||
                          localStorage.getItem("USER_LEVEL") == 18
                            ? Strings["Add Amount"]
                            : Strings["Add / Get Amount"]}
                        </DropdownItem>
                      )}
                    {(rowData["accountType"] == 17 ||
                      rowData["accountType"] == 18 ||
                      this.state.pageView != 1) &&
                      ((localStorage.getItem("USER_LEVEL") == 2 &&
                        localStorage.getItem("OPEN_BILLS") == 1 &&
                        localStorage.getItem("PERM_TYPE") == 5) ||
                        localStorage.getItem("USER_LEVEL") == 1 ||
                        localStorage.getItem("POS_MGR_EMP_NEW") == 1) && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openPosAction(event, "pos_limits", rowData)
                          }
                        >
                          {Strings["POS Limits"]}
                        </DropdownItem>
                      )}
                    {((this.state.pageView != 1 &&
                      localStorage.getItem("USER_LEVEL") == 2 &&
                      localStorage.getItem("PERM_TYPE") == 5) ||
                      (this.state.permType == 5 &&
                        this.state.pageView != 1)) && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openPosAction(
                            event,
                            "app-trans",
                            rowData,
                            "x-large"
                          )
                        }
                      >
                        {Strings["App Status"]}
                      </DropdownItem>
                    )}
                    {((this.state.pageView != 1 &&
                      localStorage.getItem("USER_LEVEL") == 2 &&
                      localStorage.getItem("PERM_TYPE") == 5) ||
                      (this.state.permType == 5 &&
                        this.state.pageView != 1)) && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openPosAction(
                            event,
                            "app-version",
                            rowData,
                            "x-large"
                          )
                        }
                      >
                        {Strings["App Version"]}
                      </DropdownItem>
                    )}
                    {localStorage.getItem("ACC_EMP") == 0 &&
                      localStorage.getItem("POS_MGR_EMP_NEW") != 1 &&
                      localStorage.getItem("USER_LEVEL") != 18 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.resetAuthentication(event, rowData.userId)
                          }
                        >
                          {Strings["Reset Authentication"]}
                        </DropdownItem>
                      )}
                    {localStorage.getItem("ACC_EMP") == 0 &&
                      localStorage.getItem("POS_MGR_EMP_NEW") != 1 &&
                      localStorage.getItem("USER_LEVEL") != 18 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.openSidebarModal(event, "edit", rowData)
                          }
                        >
                          {Strings["Edit"]}
                        </DropdownItem>
                      )}
                    {localStorage.getItem("POS_MGR_EMP") == 0 &&
                      localStorage.getItem("ACC_EMP") == 0 &&
                      localStorage.getItem("OPERA_EMP") == 0 &&
                      localStorage.getItem("POS_MGR_EMP") != 1 &&
                      localStorage.getItem("POS_MGR_EMP_NEW") != 1 &&
                      localStorage.getItem("USER_LEVEL") != 18 && (
                        <DropdownItem
                          className="actionDropdownList"
                          onClick={(event) =>
                            this.deletePos(event, rowData.userId)
                          }
                        >
                          {Strings["Delete"]}
                        </DropdownItem>
                      )}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        );
      });
    }
    return orderInfo;
  }

  render() {
    console.log(this.state.distData, "this.state.distData");
    let posName = [];
    let posInfo = [];
    let posAction = [];
    if (this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        posAction.push(
          <div
            className={rowData.userId + "class" + " row flex-row flex-nowrap"}
            style={{ height: "50px" }}
            key={rowData.userId}
          >
            <div
              className={
                this.state["activeIndex"] == rowData.userId
                  ? "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row  hover-scroll-table-row  text-center action-icon-view "
                  : "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row text-center action-icon-view "
              }
              onMouseOver={this.addRowHover.bind(this, rowData.userId)}
              onMouseOut={this.removeRowHover.bind(this, rowData.userId)}
            >
              <Switch
                checked={rowData["cardStatus"] == 1 ? true : false}
                onChange={(event) =>
                  this.handleChangeStatus(
                    event,
                    "cardStatus",
                    rowData["userId"]
                  )
                }
                name={"cardStatus-" + rowData["userId"]}
                inputProps={{ "aria-label": "secondary checkbox" }}
                size={"small"}
              />
            </div>
            <div
              className={
                this.state["activeIndex"] == rowData.userId
                  ? "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row  hover-scroll-table-row  text-center action-icon-view "
                  : "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row text-center action-icon-view "
              }
              onMouseOver={this.addRowHover.bind(this, rowData.userId)}
              onMouseOut={this.removeRowHover.bind(this, rowData.userId)}
            >
              <Switch
                checked={rowData["distStatus"] == 1 ? true : false}
                onChange={(event) =>
                  this.handleChangeStatus(
                    event,
                    "distStatus",
                    rowData["userId"]
                  )
                }
                name={"distStatus-" + rowData["userId"]}
                inputProps={{ "aria-label": "secondary checkbox" }}
                size={"small"}
              />
            </div>
            <div
              className={
                this.state["activeIndex"] == rowData.userId
                  ? "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row hover-scroll-table-row  text-center action-icon-view "
                  : "col-4 col-sm-4 col-md-4 col-lg-4 table-body-row text-center action-icon-view"
              }
              onMouseOver={this.addRowHover.bind(this, rowData.userId)}
              onMouseOut={this.removeRowHover.bind(this, rowData.userId)}
            >
              <Dropdown
                isOpen={
                  this.state.openHeader == rowData["userId"] ? true : false
                }
                className="font-size-13 "
              >
                <DropdownToggle tag="a" className="nav-link pt-0" caret>
                  <MoreVertIcon
                    className="menu-dots-vertical"
                    onClick={(event) =>
                      this.openHeaderMenu(event, rowData["userId"])
                    }
                  />
                </DropdownToggle>

                <DropdownMenu className="font-size-13 modal-left-0">
                  <div
                    className="actionDropdownList"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    {this.state.pageView != 1 && (
                      <DropdownItem
                        className="actionDropdownList"
                        tag="a"
                        href={"/inventory/2/" + rowData["userId"]}
                      >
                        {Strings["Cards"]}
                      </DropdownItem>
                    )}
                    {/* <DropdownItem className='actionDropdownList' onClick={(event) => this.openPosAction(event, 'financial-report', rowData, 'large')}>{Strings['Financial Report']}</DropdownItem> */}
                    {/* <DropdownItem className='actionDropdownList' onClick={(event) => this.openPosAction(event, 'account-statement', rowData, 'large')}>{Strings['Account Statement']}</DropdownItem> */}
                    {(rowData["accountType"] == 7 ||
                      rowData["accountType"] == 8) && (
                      <DropdownItem
                        className="actionDropdownList"
                        onClick={(event) =>
                          this.openPosAction(event, "add_get_amount", rowData)
                        }
                      >
                        {Strings["Add / Get Amount"]}
                      </DropdownItem>
                    )}
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.resetAuthentication(event, rowData.userId)
                      }
                    >
                      {Strings["Reset Authentication"]}
                    </DropdownItem>
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) =>
                        this.openSidebarModal(event, "edit", rowData)
                      }
                    >
                      {Strings["Edit"]}
                    </DropdownItem>
                    <DropdownItem
                      className="actionDropdownList"
                      onClick={(event) => this.deletePos(event, rowData.userId)}
                    >
                      {Strings["Delete"]}
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <Row>
          {this.state.pageView == 1 && !this.state.distId ? (
            <Col
              md={6}
              xs={6}
              lg={6}
              sm={12}
              className="main-color-1 inv-class-view-title"
            >
              <Paper elevation={0}>
                <Breadcrumbs
                  separator={
                    localStorage.getItem("LANG") == 1 ? (
                      <NavigateBeforeIcon fontSize="large" />
                    ) : (
                      <NavigateNextIcon fontSize="large" />
                    )
                  }
                  aria-label="Breadcrumb"
                >
                  {this.state.distId && (
                    <Link
                      className="color-bridcrim"
                      to={{
                        pathname: "/settings/admin/Distributors",
                      }}
                      href="#"
                    >
                      {" "}
                      {Strings["Distributors"]}
                    </Link>
                  )}
                  {this.state.distId && (
                    <Link
                      className="color-bridcrim"
                      to={{
                        pathname:
                          "/settings/DistAccountInfo/" + this.state.distId,
                        state: {
                          distId: this.state.distId,
                          distData: this.state.distData,
                        },
                      }}
                      href="#"
                    >
                      {" "}
                      {this.state.distData?.fname}
                    </Link>
                  )}

                  {!this.state.distId && (
                    <Link className="color-bridcrim" to="/pos" href="#">
                      {" "}
                      {Strings["My Account"]}{" "}
                    </Link>
                  )}

                  <Typography color="textPrimary">
                    {Strings["Employees"]}
                  </Typography>
                </Breadcrumbs>
              </Paper>
              <hr className="inv-hr-view-2" />
            </Col>
          ) : (
            <Col
              md={6}
              xs={6}
              lg={6}
              sm={12}
              className="main-color-1 inv-class-view-title"
            >
              {this.state.distId ? (
                <Paper elevation={0}>
                  <Breadcrumbs
                    separator={
                      localStorage.getItem("LANG") == 1 ? (
                        <NavigateBeforeIcon fontSize="large" />
                      ) : (
                        <NavigateNextIcon fontSize="large" />
                      )
                    }
                    aria-label="Breadcrumb"
                  >
                    <>
                      <Link
                        className="color-bridcrim"
                        to={{
                          pathname: "/settings/admin/Distributors",
                        }}
                        href="#"
                      >
                        {" "}
                        {Strings["Distributors"]}
                      </Link>
                    </>
                    <>
                      <Link
                        className="color-bridcrim"
                        to={{
                          pathname:
                            "/settings/DistAccountInfo/" + this.state.distId,
                          state: {
                            distId: this.state.distId,
                            distData: this.state.distData,
                          },
                        }}
                        href="#"
                      >
                        {" "}
                        {this.state.distData?.fname}
                      </Link>
                    </>

                    <Typography color="textPrimary">
                      {this.state.pageNameFlag
                        ? Strings["Employees"]
                        : Strings["Points Of Sales"]}
                    </Typography>
                  </Breadcrumbs>
                </Paper>
              ) : (
                <div>{Strings["Points Of Sales"]} </div>
              )}

              <hr className="inv-hr-view-2" />
            </Col>
          )}
        </Row>
        <div className="inv-cards-view">
          <Row className="page-filter-view">
            <Col md={4} lg={4} sm={4} xs={12}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row className="filter-form">
                  <Col sm={10} xs={12} md={10} lg={10}>
                    <AvField
                      name="searchKey"
                      type="text"
                      placeholder={Strings["Search"]}
                      value={this.state.searchKey}
                      onChange={this.handleInputChange}
                    />
                  </Col>

                  <Col sm={2} xs={12} md={2} lg={2} className="mb-2">
                    <Button
                      variant="contained"
                      className="refresh-action-btn"
                      onClick={this.handleRefresh}
                      title={Strings["Refresh"]}
                    >
                      <i className="flaticon-refresh reset-filter-btn"></i>
                    </Button>
                  </Col>
                  {this.state.pageView == 1 && (
                    <Col xs={12} lg={12} md={12} sm={12}>
                      <div className="member-searchable-list mb-2 mt-1">
                        <Picky
                          value={this.state.selectedAcountTypes}
                          options={this.state.accountTypes}
                          onChange={this.handleChangeSelect.bind(
                            this,
                            "selectedAcountTypes"
                          )}
                          open={false}
                          valueKey="user_type"
                          labelKey={
                            localStorage.getItem("LANG") == 1
                              ? "name_ar"
                              : "name_en"
                          }
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          placeholder={Strings["Acount Type"]}
                          filterPlaceholder={Strings["Select Acount Type"]}
                          selectAllText={Strings["Select All"]}
                          allSelectedPlaceholder={Strings["%s selected"]}
                          manySelectedPlaceholder={Strings["%s selected"]}
                          clearFilterOnClose={true}
                        />
                      </div>
                    </Col>
                  )}
                  {(localStorage.getItem("PERM_TYPE") == 5 ||
                    this.state.permType == 5 ||
                    localStorage.getItem("ACC_EMP") == 1 ||
                    localStorage.getItem("OPERA_EMP") == 1) &&
                    this.state.pageView != 1 && (
                      <Col xs={6} lg={6} md={6} sm={12}>
                        <div className="member-searchable-list mb-2">
                          <Picky
                            value={this.state.selectedMangerPos}
                            options={this.state.mangerPos}
                            onChange={this.handleChangeSelect.bind(
                              this,
                              "selectedMangerPos"
                            )}
                            open={false}
                            valueKey="userId"
                            labelKey="fname"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            dropdownHeight={200}
                            placeholder={Strings["POS Manager Name"]}
                            filterPlaceholder={Strings["Select POS Manager"]}
                            selectAllText={Strings["Select All"]}
                            allSelectedPlaceholder={Strings["%s selected"]}
                            manySelectedPlaceholder={Strings["%s selected"]}
                            clearFilterOnClose={true}
                          />
                        </div>
                      </Col>
                    )}
                  {(localStorage.getItem("PERM_TYPE") == 5 ||
                    this.state.permType == 5 ||
                    localStorage.getItem("ACC_EMP") == 1 ||
                    localStorage.getItem("OPERA_EMP") == 1) &&
                    this.state.pageView != 1 && (
                      <Col xs={6} lg={6} md={6} sm={12}>
                        <div className="member-searchable-list mb-2">
                          <Picky
                            value={this.state.distClassListSelected}
                            options={this.state.distClassList}
                            onChange={this.handleChangeSelect.bind(
                              this,
                              "distClassListSelected"
                            )}
                            open={false}
                            valueKey="id"
                            labelKey="name"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            dropdownHeight={200}
                            placeholder={Strings["Pos Categories Name"]}
                            filterPlaceholder={Strings["Select Pos Categories"]}
                            selectAllText={Strings["Select All"]}
                            allSelectedPlaceholder={Strings["%s selected"]}
                            manySelectedPlaceholder={Strings["%s selected"]}
                            clearFilterOnClose={true}
                          />
                        </div>
                      </Col>
                    )}
                  {(localStorage.getItem("PERM_TYPE") == 5 ||
                    this.state.permType == 5 ||
                    localStorage.getItem("ACC_EMP") == 1 ||
                    localStorage.getItem("OPERA_EMP") == 1) &&
                    this.state.pageView != 1 && (
                      <Col xs={6} lg={6} md={6} sm={12}>
                        <div className="member-searchable-list mb-2">
                          <Picky
                            value={this.state.selectedStatusType}
                            options={this.state.statusTypes}
                            onChange={this.handleChangeSelect.bind(
                              this,
                              "selectedStatusType"
                            )}
                            open={false}
                            valueKey="value"
                            labelKey="title"
                            multiple={false}
                            includeSelectAll={true}
                            includeFilter={true}
                            dropdownHeight={200}
                            placeholder={Strings["POS Status"]}
                            filterPlaceholder={Strings["Select POS Status"]}
                            selectAllText={Strings["Select All"]}
                            allSelectedPlaceholder={Strings["%s selected"]}
                            manySelectedPlaceholder={Strings["%s selected"]}
                            clearFilterOnClose={true}
                          />
                        </div>
                      </Col>
                    )}
                </Row>
              </AvForm>
            </Col>
            <Col md={8} lg={8} sm={8} xs={12} className="text-right">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                {((localStorage.getItem("ACC_EMP") == 0 &&
                  (localStorage.getItem("POS_MGR_EMP_NEW") != 1 ||
                    localStorage.getItem("USER_LEVEL") != 18)) ||
                  ((localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                    localStorage.getItem("USER_LEVEL") == 18) &&
                    this.state.pageView != 1)) && (
                  <button
                    className="btn gradient-button-view gradient-button gradient-button-font-12 m-1"
                    onClick={(event) => this.openSidebarModal(event, "add", "")}
                  >
                    {(localStorage.getItem("PERM_TYPE") == 5 ||
                      this.state.permType == 5 ||
                      ((localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                        localStorage.getItem("USER_LEVEL") == 18) &&
                        this.state.pageView != 1) ||
                      localStorage.getItem("POS_MGR_EMP") == 1 ||
                      localStorage.getItem("OPERA_EMP") == 1) &&
                    this.state.pageView != 1
                      ? Strings["Add POS"]
                      : Strings["Add Employee"]}
                  </button>
                )}
                {// localStorage.getItem("PERM_TYPE") == 5 ||
                // this.state.permType == 5 ||
                // localStorage.getItem("PERM_TYPE") == 1 ||
                // this.state.permType == 1 ||
                localStorage.getItem("USER_LEVEL") == 1 && (
                  <button
                    className="btn page-action-btn m-1 max-w-ss"
                    onClick={(event) => this.exportHandler(event)}
                  >
                    {Strings["Export"]}
                  </button>
                )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("OPERA_EMP") == 1) &&
                  this.state.loadingSave == 0 &&
                  this.state.pageView != 1 && (
                    <button
                      value={this.state.allCkecked == 0 ? "0" : "1"}
                      className="btn page-action-btn m-1"
                      onClick={(event) => this.handleActivateAllCard(event)}
                    >
                      {this.state.allCkecked == 0
                        ? Strings["Deactivate"]
                        : Strings["Activate"]}{" "}
                      {Strings["All Cards"]}
                    </button>
                  )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("OPERA_EMP") == 1) &&
                  this.state.loadingSave == 1 &&
                  this.state.pageView != 1 && (
                    <button
                      value={this.state.allCkecked == 0 ? "0" : "1"}
                      className="btn page-action-btn m-1"
                    >
                      {this.state.allCkecked == 0
                        ? Strings["Deactivate"]
                        : Strings["Activate"]}{" "}
                      {Strings["All Cards"]}{" "}
                      <CircularProgress className="SaveCircularProgressColor" />
                    </button>
                  )}
              </div>

              {(localStorage.getItem("OPERA_EMP") == 1 ||
                localStorage.getItem("PERM_TYPE") == 5 ||
                this.state.permType == 5 ||
                this.state.permType == 1 ||
                localStorage.getItem("PERM_TYPE") == 1 ||
                localStorage.getItem("POS_MGR_EMP") == 1 ||
                localStorage.getItem("ACC_EMP") == 1) &&
                this.state.loading == 0 &&
                this.state.data.length > 0 &&
                this.state.pageView != 1 && (
                  <Row className="mr-1 ml-1">
                    <Col md={4} lg={6} sm={12} xs={12}></Col>
                    <Col
                      md={8}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="mt-3 algin-total-box financail-operation-report-summary-box-view"
                    >
                      <div>
                        {(localStorage.getItem("OPERA_EMP") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5 ||
                          this.state.permType == 5 ||
                          localStorage.getItem("POS_MGR_EMP") == 1 ||
                          localStorage.getItem("ACC_EMP") == 1) && (
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {this.state.stringTotal}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-md-right"
                            >
                              {this.state.totalBalancePos}
                            </Col>
                          </Row>
                        )}
                        {(localStorage.getItem("PERM_TYPE") == 5 ||
                          this.state.permType == 5 ||
                          localStorage.getItem("PERM_TYPE") == 1 ||
                          this.state.permType == 1 ||
                          localStorage.getItem("USER_LEVEL") == 1) && (
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {Strings["totalPosCount"]}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-md-right"
                            >
                              {this.state.totalPosCount}
                            </Col>
                          </Row>
                        )}
                        {(localStorage.getItem("PERM_TYPE") == 5 ||
                          this.state.permType == 5 ||
                          localStorage.getItem("PERM_TYPE") == 1 ||
                          this.state.permType == 1 ||
                          localStorage.getItem("USER_LEVEL") == 1) && (
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {this.state.stringActivePos}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-md-right"
                            >
                              {this.state.activePos}
                            </Col>
                          </Row>
                        )}
                        {(localStorage.getItem("PERM_TYPE") == 5 ||
                          this.state.permType == 5 ||
                          localStorage.getItem("PERM_TYPE") == 1 ||
                          this.state.permType == 1 ||
                          localStorage.getItem("USER_LEVEL") == 1) && (
                          <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable">
                            <Col
                              md={8}
                              xs={12}
                              lg={8}
                              sm={12}
                              className="main-color-1 font-w-400"
                            >
                              {this.state.stringNotActivePos}
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              lg={4}
                              sm={12}
                              className="text-md-right"
                            >
                              {this.state.notActivePos}
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {(this.state.permType == 5 ||
                localStorage.getItem("PERM_TYPE") == 5) &&
                this.state.loading == 0 &&
                this.state.data.length > 0 &&
                this.state.pageView == 1 && (
                  <Row className="mr-1 ml-1">
                    <Col md={4} lg={6} sm={6} xs={12}></Col>
                    <Col
                      md={8}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="mt-3 status-square-view-emp"
                    >
                      <div>
                        <Row className="pos-financail-report-summary-row financail-operation-report-summary-lable algin-total-box">
                          <Col
                            md={8}
                            xs={12}
                            lg={8}
                            sm={12}
                            className="main-color-1 font-w-400"
                          >
                            {this.state.stringTotal}
                          </Col>
                          <Col md={4} xs={12} lg={4} sm={12}>
                            {this.state.totalBalancePos}
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-4">
                        <Row>
                          <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="task-square-div">
                              <div className="status-square-3 "></div>{" "}
                              <p className="mb-0 mr-1 ">
                                {" "}
                                {Strings["Manger POS"]}{" "}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="task-square-div">
                              <div className="status-square-2 "></div>{" "}
                              <p className="mb-0 mr-1 ">
                                {" "}
                                {Strings["Accountant"]}{" "}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="task-square-div">
                              <div className="status-square-4 "></div>{" "}
                              <p className="mb-0 mr-1">
                                {" "}
                                {Strings["Actions Director"]}{" "}
                              </p>
                            </div>
                          </Col>
                          {((localStorage.getItem("OPEN_BILLS") == 1 &&
                            localStorage.getItem("PERM_TYPE") == 5) ||
                            localStorage.getItem("USER_LEVEL") == 1) && (
                            <Col lg={4} md={4} sm={4} xs={12}>
                              <div className="task-square-div">
                                <div className="status-square-10 "></div>{" "}
                                {/* status-10-color */}
                                <p className="mb-0 mr-1 ">
                                  {" "}
                                  {Strings["POS Supervisor"]}{" "}
                                </p>
                              </div>
                            </Col>
                          )}
                          {((localStorage.getItem("OPEN_BILLS") == 1 &&
                            localStorage.getItem("PERM_TYPE") == 5) ||
                            localStorage.getItem("USER_LEVEL") == 1) && (
                            <Col lg={4} md={4} sm={4} xs={12}>
                              <div className="task-square-div">
                                <div className="status-square-9 "></div>{" "}
                                {/* status-10-color */}
                                <p className="mb-0 mr-1 ">
                                  {" "}
                                  {Strings["POS Supervisor Supervisor"]}{" "}
                                </p>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
        </div>
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">{this.state.errorMsg}</div>
            </div>
          </div>
        )}

        {this.state.loading == 0 && this.state.data.length <= 0 && (
          <div className="inv-cards-view pb-5">
            <div
              className="text-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl(
                  "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                )}
                className="img-fluid no-data-img"
              />
              <div className="no-data-text">
                {Strings["There is no data to display"]}
              </div>
            </div>
          </div>
        )}

        {this.state.loading == 1 && (
          <Row>
            <Col
              md={12}
              xs={12}
              lg={12}
              sm={12}
              style={{ marginTop: "15%" }}
              className="text-center"
            >
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}

        {/* ///Test table// */}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row
                className={`${this.state.mainClassHeader} d-lg-flex d-md-flex d-none`}
              >
                <Col
                  lg={this.state.colPosName}
                  md={this.state.colPosName}
                  sm={this.state.colPosName}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Name"]}
                  onClick={() => this.sortCoulmn(1, "fname")}
                >
                  {" "}
                  {this.sortIcon(1)}
                  {Strings["Name"]}{" "}
                </Col>
                <Col
                  lg={this.state.colJoinDate}
                  md={this.state.colJoinDate}
                  sm={this.state.colJoinDate}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Subscription Date"]}
                  onClick={() => this.sortCoulmn(2, "joinDate")}
                >
                  {" "}
                  {this.sortIcon(2)}
                  {Strings["Subscription Date"]}
                </Col>
                {(localStorage.getItem("ACC_EMP") == 1 ||
                  localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("OPERA_EMP") == 1) &&
                  this.state.pageView != 1 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["POS Manager"]}
                    >
                      {" "}
                      {Strings["POS Manager"]}
                    </Col>
                  )}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  this.state.permType == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("OPERA_EMP") == 1 ||
                  localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                  localStorage.getItem("USER_LEVEL") == 18 ||
                  localStorage.getItem("ACC_EMP") == 1) && (
                  <Col
                    lg={this.state.colBal}
                    md={this.state.colBal}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Balance"]}
                    onClick={() => this.sortCoulmn(3, "distBalanceAmount")}
                  >
                    {this.sortIcon(3)} {Strings["Balance"]}{" "}
                  </Col>
                )}
                <Col
                  lg={this.state.colPhone}
                  md={this.state.colPhone}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Phone"]}
                >
                  {Strings["Phone"]}
                </Col>
                <Col
                  lg={this.state.colEmail}
                  md={this.state.colEmail}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Email"]}
                >
                  {Strings["Email"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Region"]}
                  onClick={() => this.sortCoulmn(4, "address")}
                >
                  {this.sortIcon(4)}
                  {Strings["Region"]}
                </Col>
                {this.state.pageView != 1 && (
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Category"]}
                  >
                    {Strings["Category"]}
                  </Col>
                )}
                {this.state.pageView == 1 &&
                  (localStorage.getItem("USER_LEVEL") == 1 ||
                    (localStorage.getItem("PERM_TYPE") == 5 &&
                      localStorage.getItem("OPEN_BILLS") == 1)) && (
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Debt Value"]}
                    >
                      {Strings["Debt Value"]}
                    </Col>
                  )}{" "}
                {this.state.pageView == 1 &&
                  (localStorage.getItem("USER_LEVEL") == 1 ||
                    (localStorage.getItem("PERM_TYPE") == 5 &&
                      localStorage.getItem("OPEN_BILLS") == 1)) && (
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Receivables Value"]}
                    >
                      {Strings["Receivables Value"]}
                    </Col>
                  )}
                {this.state.pageView == 1 && (
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Acount Type"]}
                  >
                    {Strings["Acount Type"]}
                  </Col>
                )}
                {/* {this.state.pageView != 1 &&
                  localStorage.getItem("POS_MGR_EMP") == 0 &&
                  localStorage.getItem("ACC_EMP") == 0 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["All Cards"]}
                    >
                      {Strings["All Cards"]}
                    </Col>
                  )} */}
                {/* {((this.state.pageView != 1 &&
                  localStorage.getItem("IS_TAXDIST") == 1 &&
                  localStorage.getItem("USER_LEVEL") == 2 &&
                  localStorage.getItem("PERM_TYPE") == 5) ||
                  (this.state.permType == 5 &&
                    this.state.isTaxDist &&
                    this.state.pageView != 1)) && (
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length"
                    title={Strings["Tax"]}
                  >
                    {Strings["Tax"]}
                  </Col>
                )} */}
                {/* {localStorage.getItem("POS_MGR_EMP") == 0 &&
                  localStorage.getItem("ACC_EMP") == 0 && (
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Status"]}
                    >
                      {Strings["Status"]}
                    </Col>
                  )} */}
                <Col
                  lg={this.state.pageView != 1 ? 2 : 1}
                  md={this.state.pageView != 1 ? 2 : 1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length text-right"
                  title={Strings["Action"]}
                >
                  <div className="d-flex flex-row justify-content-between">
                    {((this.state.pageView != 1 &&
                      localStorage.getItem("IS_TAXDIST") == 1 &&
                      localStorage.getItem("USER_LEVEL") == 2 &&
                      localStorage.getItem("PERM_TYPE") == 5) ||
                      (this.state.isTaxDist == 1 &&
                        this.state.permType == 5 &&
                        this.state.pageView != 1)) && <p>{Strings["Tax"]}</p>}
                    {this.state.pageView != 1 &&
                      localStorage.getItem("POS_MGR_EMP") == 0 &&
                      localStorage.getItem("ACC_EMP") == 0 && (
                        <p>{Strings["Cards"]}</p>
                      )}
                    {localStorage.getItem("POS_MGR_EMP") == 0 &&
                      localStorage.getItem("ACC_EMP") == 0 && (
                        <p>{Strings["Status"]}</p>
                      )}

                    <p>{Strings["Action"]}</p>
                  </div>
                </Col>
              </Row>
              {this.displayData()}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Col md={12} xs={12} lg={12} sm={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
        {/* ///Test table// */}

        {/* //Drawer1Start */}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          <EmpForm
            handleCloseSideBar={this.handleCloseSideBar}
            handleFormAction={this.handleFormAction}
            status={this.state.status}
            pageView={this.state.pageView} //in employee case used only
            accountTypes={this.state.accountTypes} //in employee case used only
            empData={this.state.empData}
            mangerPos={this.state.mangerPos}
            distId={this.state.distId}
            permType={this.state.permType}
            distData={this.state.distData}
          />
        </Drawer>
        {/* //Drawer1End */}
        {/* //Drawer2Start */}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openPosAction}
          className={
            this.state.viewMode == "large"
              ? "sidebar-modal-large-view view-supplier-transaction-modal"
              : this.state.viewMode == "x-large"
              ? "sidebar-modal-x-large-view view-supplier-transaction-modal"
              : "sidebar-modal-view"
          }
        >
          {this.state.actionType == "add_get_amount" && (
            <AddOrGetAmount
              posData={this.state.posData}
              handleCloseSideBar={this.handleClosePosAction}
              recallGetData={this.recallGetData}
              pageView={this.state.pageView}
              distId={this.state.distId}
              permType={this.state.permType}
            />
          )}
          {this.state.actionType == "pos_limits" && (
            <PosLimits
              posData={this.state.posData}
              distId={this.state.distId}
              permType={this.state.permType}
              handleCloseSideBar={this.handleClosePosAction}
              pageView={this.state.pageView}
            />
          )}
          {this.state.actionType == "financial-report" && (
            <PosFinancialReport
              posData={this.state.posData}
              distId={this.state.distId}
              permType={this.state.permType}
              handleCloseSideBar={this.handleClosePosAction}
              pageView={this.state.pageView}
            />
          )}

          {this.state.actionType == "app-trans" && (
            <ViewAppTrans
              distId={this.state.distId}
              posData={this.state.posData}
              handleCloseSideBar={this.handleClosePosAction}
            />
          )}

          {this.state.actionType == "app-version" && (
            <ViewAppVersion
              distId={this.state.distId}
              posData={this.state.posData}
              handleCloseSideBar={this.handleClosePosAction}
            />
          )}

          {this.state.actionType == "account-statement" && (
            <AccountStatement
              permType={this.state.permType}
              posData={this.state.posData}
              distId={this.state.distId}
              handleCloseSideBar={this.handleClosePosAction}
            />
          )}
          {this.state.actionType == "debitStatus" && (
            <div className="view-modal-style">
              <div className="sidebar-header-view">
                <Row>
                  <Col xs={10} lg={10} md={10} sm={10}>
                    <div className="p-0 main-color-1  sidebar-header-title">
                      {" "}
                      {Strings["debitFlow"]}
                    </div>
                  </Col>
                  <Col md={2} xs={2} lg={2} sm={2} className="text-right">
                    <HighlightOffIcon
                      onClick={this.handleClosePosAction}
                      className="modal-close-icon"
                    />
                  </Col>
                </Row>
              </div>
              <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
                <DebitStatus supervisorId={this.state.posData?.userId} />
              </div>
            </div>
          )}
          {this.state.actionType == "ReceivablesFlow" && (
            <div className="view-modal-style">
              <div className="sidebar-header-view">
                <Row>
                  <Col xs={10} lg={10} md={10} sm={10}>
                    <div className="p-0 main-color-1  sidebar-header-title">
                      {" "}
                      {Strings["ReceivablesFlow"]}
                    </div>
                  </Col>
                  <Col md={2} xs={2} lg={2} sm={2} className="text-right">
                    <HighlightOffIcon
                      onClick={this.handleClosePosAction}
                      className="modal-close-icon"
                    />
                  </Col>
                </Row>
              </div>
              <div className="sidebar-body-view sidebar-body-view-supplier-transaction">
                <ReceivablesFlow supervisorId={this.state.posData?.userId} />
              </div>
            </div>
          )}
        </Drawer>
        {/* //Drawer2End */}
      </div>
    );
  }
}
export default withRouter(PosEmp);
