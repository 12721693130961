import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import { translations } from "../../../translate";

const Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export function ErrorPage1() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 new-404-page-container"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
          }}
        >
          <div className="kt-error-v1__container new-404-page">
            <h1 className="kt-error-v1__number">404</h1>
            <p className="kt-error-v1__desc">
              {Strings["OOPS! Something went wrong here"]}
            </p>
            <button
              onClick={() => {
                window.location = '/';
              }}
              className="btn form-save-btn "
            >
              {" "}
              {Strings["Go Back Home"]}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
