/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { Component, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import rtlStyles from "./RtlCssBundleService";
import { MyContextProvider } from "./app/Context/MyContext";

function changeDir() {
  if (localStorage.getItem("LANG") == 1) {
    rtlStyles.insert();
  } else {
    rtlStyles.detach();
  }
}

function getLoggedInUser() {
  if (
    localStorage.getItem("USER_ID") != null &&
    localStorage.getItem("USER_ID") != undefined &&
    localStorage.getItem("USER_ID") != ""
  ) {
    return localStorage.getItem("USER_ID");
  } else {
    return null;
  }
}
function isUserAuthenticated() {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const currentTime = new Date().setDate(new Date().getDate());
  const loginDate = localStorage.getItem("loginDate");
  if (loginDate < currentTime) {
    localStorage.setItem("USER_ID", "");
    localStorage.setItem("PERM_DIST", "");
    localStorage.setItem("PERM_TYPE", "");
    localStorage.setItem("USER_TOKEN", "");
    localStorage.setItem("IS_TAXDIST", "");
    localStorage.setItem("USER_EMAIL", "");
    localStorage.setItem("loginDate", "");
    localStorage.setItem("USER_FULL_NAME", "");
    if (
      localStorage.getItem("LANG") == undefined ||
      localStorage.getItem("LANG") == "" ||
      localStorage.getItem("LANG") == null
    ) {
      localStorage.setItem("LANG", 2);
    }
    localStorage.setItem("SHOW_COUPONS", "");
    window.location = "/login";
  } else {
    return true;
  }
  return true;
}

export default function App({ store, persistor, basename }) {
  const getIp = async () => {
    try {
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const { ip } = await ipResponse.json();
      localStorage.setItem("DEVICE_IP", ip);
    } catch (error) {
      console.log("Error getting an IP :", error);
    }
  };
  useEffect(() => {
    getIp();
    document.onkeydown = (e) => {
      let key = e.key;
      if (key === "<" || key === ">" || key === "=") {
        e.preventDefault();
        return false;
      }
    };
  }, []);
  changeDir();
  isUserAuthenticated();

  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <MyContextProvider>
                    <Routes />
                  </MyContextProvider>
                </I18nProvider>
              </ThemeProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
