import React, { Component } from "react";
import { translations } from "../../../translate.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import SearchIcon from "@material-ui/icons/Search";
import { toAbsoluteUrl } from "../../../_metronic/index.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
let today = new Date();
let currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
export default class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loadingSave: 0,
      limit: 25,
      activePage: 1,
      dataCount: 0,
      errorMsg: "",
      dateFrom: currentDate,
      dateTo: currentDate,
      data: [],
    };
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {}
    );
  };
  handleChangeSelect = (type, option) => {
    console.log(option);
    this.setState(
      (state) => {
        return {
          [type]: option,
        };
      },
      () => {}
    );
  };
  handleRefresh = () => {
    this.setState(
      {
        loading: 0,
        loadingSave: 0,
        limit: 25,
        activePage: 1,
        dataCount: 0,
        errorMsg: "",
        fromDate: currentDate,
        toDate: currentDate,
        data: [],
      },
      function() {
        this.handleSearch();
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.handleSearch();
    });
  };
  handleSearch = async () => {
    try {
      this.setState({ loading: 1 });
      const body = JSON.stringify({
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        type: "getHistoryAllAccount",
        start: this.state.activePage,
        limit: this.state.limit,
        export: 0,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        dateType: "loginDate",
      });
      const response = await fetch(window.API_PERM_USER, {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else if (data.error == 1) {
          this.setState({ errorMsg: data["errorMsg"] });
        } else {
          this.setState({
            data: data["data"],
            dataCount: data.count,
          });
        }
      }
    } catch (error) {
      this.setState({ errorMsg: error.message, data: [], activePage: 1 });
    } finally {
      this.setState({ loading: 0 });
    }
  };
  exportHandler = async () => {
    const params = {
      lang: localStorage.getItem("LANG"),
      userId: localStorage.getItem("USER_ID"),
      token: localStorage.getItem("USER_TOKEN"),
      type: "getHistoryAllAccount",
      page: this.state.activePage,
      limit: this.state.limit,
      export: 1,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      dateType: "loginDate",
    };
    const queryString = new URLSearchParams(params).toString();
    window.location = `${window.API_PERM_USER_EXPORT}?${queryString}`;
  };
  componentDidMount() {
    this.handleSearch();
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={12} className="main-color-1 inv-class-view-title mb-4">
            <div>{Strings["Activity log"]}</div>
            <hr className="inv-hr-view-2" />
          </Col>
        </Row>
        <Row className="page-filter-view">
          <Col
            xl={{ size: 5, order: 1 }}
            lg={{ size: 7, order: 1 }}
            md={{ size: 7, order: 1 }}
            sm={{ size: 10, order: 1 }}
            xs={{ size: 12, order: 12 }}
          >
            <AvForm ref={(c) => (this.form = c)}>
              <Row className="filter-form">
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="dateFrom"
                    type="date"
                    label={`${Strings["From Date"]}`}
                    value={this.state.dateFrom}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="dateTo"
                    type="date"
                    label={`${Strings["To Date"]}`}
                    value={this.state.dateTo}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col
            xl={{ size: 7, order: 1 }}
            lg={{ size: 5, order: 12 }}
            md={{ size: 5, order: 12 }}
            sm={{ size: 2, order: 12 }}
            xs={{ size: 12, order: 1 }}
            className="text-right mb-3 "
          >
            <div className="d-flex flex-wrap flex-column align-items-end gap-3 w-100">
              <button
                className="btn page-action-btn ml-1 mb-2"
                onClick={(event) => this.exportHandler(event)}
              >
                {Strings["Export"]}
              </button>
              <button
                variant="contained"
                className="btn form-save-btn ml-1 mr-1 mb-2"
                onClick={this.handleSearch}
              >
                {Strings["Search"]}{" "}
                <SearchIcon className="font-size-15  main-color-2" />
              </button>
              <Col xs={12} lg={1} md={1} sm={12}>
                <Button
                  variant="contained"
                  type="button"
                  className="refresh-action-btn mb-2 mb-md-0"
                  onClick={this.handleRefresh}
                  title={Strings["Refresh"]}
                >
                  <i className="flaticon-refresh reset-filter-btn"></i>
                </Button>
              </Col>
            </div>
          </Col>
        </Row>
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}
        {this.state.loading == 0 &&
          this.state.data.length <= 0 &&
          this.state.errorMsg == "" && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </div>
            </Row>
          )}
        {this.state.loading == 1 && (
          <Row>
            <Col lg={12} style={{ marginTop: "7%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row d-none d-lg-flex d-md-flex">
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["User Id"]}
                >
                  {Strings["User Id"]}
                </Col>

                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["User Name"]}
                >
                  {Strings["User Name"]}
                </Col>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Last Login"]}
                >
                  {Strings["Last Login"]}
                </Col>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Last Logout"]}
                >
                  {Strings["Last Logout"]}
                </Col>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["IP Address"]}
                >
                  {Strings["IP Address"]}
                </Col>
              </Row>
              {this.state.data.map((rowData, index) => {
                return (
                  <Row key={index} className="table-body-row">
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["User Id"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["User Id"]} :{" "}
                      </span>
                      {rowData.userId}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["User Name"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["User Name"]} :{" "}
                      </span>
                      {`${rowData.fname} ${rowData.lname}`}
                    </Col>

                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["Last Login"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Last Login"]} :{" "}
                      </span>
                      {rowData.loginDate}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["Last Logout"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Last Logout"]} :{" "}
                      </span>
                      {rowData.logoutDate}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["IP Address"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["IP Address"]} :{" "}
                      </span>
                      {rowData.ip}
                    </Col>
                  </Row>
                );
              })}
              {this.state.loading == 0 && this.state.data?.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
      </div>
    );
  }
}
