import React, { Component } from "react";
import { translations } from "../../../translate.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import SearchIcon from "@material-ui/icons/Search";
import { toAbsoluteUrl } from "../../../_metronic/index.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendPayRequestForm from "./forms/SendPayRequestForm.jsx";
import PayDetails from "./forms/PayDetails.jsx";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "rc-tooltip";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
let today = new Date();
let currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

export default class PayManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loadingSave: 0,
      limit: 25,
      activePage: 1,
      dataCount: 0,
      errorMsg: "",
      fromDate: currentDate,
      toDate: currentDate,
      data: [],
      distributorList: [],
      distId: null,
      subervisorId: [],
      posId: [],
      billState: "",
      billType: "",
      billTypes: [],
      BillStates: [],
      sentDateFrom: currentDate,
      sentDateTo: currentDate,
      confirmDateFrom: currentDate,
      confirmDateTo: currentDate,
      openleftModal: false,
      payState: "",
      payStates: [],
      tranTypes: [],
      tranType: this.props.type
        ? localStorage.getItem("USER_LEVEL") == 18
          ? "6"
          : "1"
        : "",
      // tranType:  "",
      senderId: [],
      sendersList: [],
      receiverId: [],
      receiverList: [],
      openModal: false,
      modalData: null,
      posList: [],
      selectedPos: [],
    };
  }
  openSidebarModal = (type = null) => {
    this.setState({ openleftModal: !this.state.openleftModal, type });
  };
  closeSidebarModal = () => {
    this.setState({ openleftModal: false }, () => {
      this.handleSearch();
    });
  };
  openSidebarModal2 = (modalData = null) => {
    this.setState({ openModal: !this.state.openModal, modalData });
  };
  closeSidebarModal2 = () => {
    this.setState({ openModal: false, modalData: null }, () => {});
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        if (name == "tranType") {
          this.setState(
            {
              senderId: [],
              receiverId: [],
              sendersList: [],
              receiverList: [],
            },
            function() {
              this.getReceivers();
              this.getSenders();
            }
          );
        }
      }
    );
  };
  getPos() {
    if (
      this.state.distId?.userId != undefined ||
      localStorage.getItem("PERM_TYPE") == 5
    ) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          distId: this.state.distId?.userId,
          getType: "all",
          accountType: 7,
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState({
                posList: data["data"],
              });
            }
          }
        });
    }
  }
  handleChangeSelect = (type, option) => {
    console.log(option);
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
          errorMsg: 0,
        };
      },
      () => {
        if (localStorage.getItem("USER_LEVEL") == 1 && type == "distId") {
          this.getSenders();
        }
        if (
          localStorage.getItem("USER_LEVEL") == 1 &&
          type == "distId" &&
          !this.props.type
        ) {
          this.getPos();
        }
      }
    );
  };
  handleRefresh = () => {
    this.setState(
      {
        limit: 25,
        activePage: 1,
        dataCount: 0,
        errorMsg: "",
        fromDate: currentDate,
        toDate: currentDate,
        data: [],
        distId: [],
        subervisorId: [],
        posId: [],
        billState: "",
        billType: "",
        sentDateFrom: currentDate,
        sentDateTo: currentDate,
        confirmDateFrom: currentDate,
        confirmDateTo: currentDate,
        loading: 0,
        loadingSave: 0,
        payState: "",
        tranType: this.props.type ? "1" : "",
        senderId: [],
        sendersList: [],
        receiverId: [],
        receiverList: [],
        posList: [],
        selectedPos: [],
      },
      function() {
        this.getPayState();
        this.getSenders();
        this.getReceivers();
        this.getTranType();
        if (localStorage.getItem("USER_LEVEL") == 1) {
          this.getAllDist();
        }
        if (localStorage.getItem("PERM_TYPE") == 5) {
          this.getPos();
        }
      }
    );
  };
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  handleSearch = async () => {
    try {
      this.setState({ loading: 1 });
      const body = JSON.stringify({
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        type: "getPayRequests",
        distId:
          this.state.distId?.userId != undefined
            ? [this.state.distId?.userId]
            : [],
        senderId:
          (localStorage.getItem("USER_LEVEL") == 1 ||
            localStorage.getItem("PERM_TYPE") == 5) &&
          !this.props.type
            ? this.state.posId.map((e) => e.userId)
            : this.state.senderId
            ? this.state.senderId.map((e) =>
                localStorage.getItem("USER_LEVEL") == 1 ||
                localStorage.getItem("PERM_TYPE") == 5
                  ? e.userId
                  : e.permId
              )
            : [],
        receiverId: this.state.receiverId
          ? this.state.receiverId.map((e) =>
              localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("PERM_TYPE") == 5
                ? e.userId
                : e.permId
            )
          : [],
        status: this.state.payState,
        tranType: this.props.type ? this.state.tranType : "0",
        sentDateFrom: this.state.sentDateFrom,
        sentDateTo: this.state.sentDateTo,
        confirmDateFrom: this.state.confirmDateFrom,
        confirmDateTo: this.state.confirmDateTo,
        page: this.state.activePage,
        limit: this.state.limit,
        export: 0,
        getType: "limit",
      });
      const response = await fetch(window.API_PERM_USER, {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data.error == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else if (data.error == 0) {
            this.setState({
              data: data["PayRequests"],
              dataCount: data.totalCount,
              status: data.status,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ errorMsg: error.message, data: [], activePage: 1 });
    } finally {
      this.setState({ loading: 0 });
    }
  };
  exportHandler = () => {
    const params = {
      lang: localStorage.getItem("LANG"),
      userId: localStorage.getItem("USER_ID"),
      token: localStorage.getItem("USER_TOKEN"),
      type: "getPayRequests",
      distId:
        this.state.distId?.userId != undefined
          ? [this.state.distId?.userId]
          : [],
      senderId: this.state.senderId
        ? this.state.senderId.map((e) =>
            localStorage.getItem("USER_LEVEL") == 1 ? e.userId : e.permId
          )
        : [],
      receiverId: this.state.receiverId
        ? this.state.receiverId.map((e) =>
            localStorage.getItem("USER_LEVEL") == 1 ? e.userId : e.permId
          )
        : [],
      status: this.state.payState,
      tranType: this.props.type ? this.state.tranType : "0",
      sentDateFrom: this.state.sentDateFrom,
      sentDateTo: this.state.sentDateTo,
      confirmDateFrom: this.state.confirmDateFrom,
      confirmDateTo: this.state.confirmDateTo,
      page: this.state.activePage,
      limit: this.state.limit,
      export: 1,
      getType: "limit",
    };
    const queryString = new URLSearchParams(params).toString();
    window.location = `${window.API_PERM_USER_EXPORT}?${queryString}`;
  };
  getTranType = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getTranType",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        lang: localStorage.getItem("LANG"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
          return;
        }
        this.setState({ tranTypes: data.billsType });
      });
  };
  getPayState = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getPayState",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        lang: localStorage.getItem("LANG"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
          return;
        }
        this.setState({ payStates: data.billsType });
      });
  };
  getSenders = () => {
    if (this.state.tranType === "0") {
      if (
        localStorage.getItem("USER_LEVEL") == 3 &&
        localStorage.getItem("ACCOUNT_TYPE") == 7
      ) {
        return;
      }
      if (localStorage.getItem("USER_LEVEL") != 8) {
        if (
          localStorage.getItem("USER_LEVEL") == 1 &&
          this.state.distId?.userId != undefined
        ) {
          return;
        } else {
          fetch(window.API_PERM_USER, {
            method: "POST",
            body: JSON.stringify({
              type: "getDistPos",
              lang: localStorage.getItem("LANG"),
              userId: localStorage.getItem("USER_ID"),
              token: localStorage.getItem("USER_TOKEN"),
              getType: "all",
              accountType: 7,
              distId:
                this.state.distId?.userId != undefined
                  ? [this.state.distId?.userId]
                  : "",
            }),
            headers: {
              Accept: "application/json",
            },
          })
            .then(function(response) {
              return response.json();
            })
            .then((data) => {
              if (data !== "Nothing found") {
                if (data["logout"] == 1) {
                  localStorage.setItem("USER_ID", "");
                  localStorage.setItem("USER_TOKEN", "");
                  window.location.href = "/logout";
                } else {
                  this.setState(
                    {
                      sendersList: data["data"],
                    },
                    function() {}
                  );
                }
              }
            });
        }
      } else if (
        localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18
      ) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getPerm",
            permType: 7,
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            lang: localStorage.getItem("LANG"),
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
              return;
            }
            this.setState({ sendersList: data });
          });
      }
    } else if (this.state.tranType === "1") {
      if (
        localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18
      ) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getPerm",
            permType: 8,
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            lang: localStorage.getItem("LANG"),
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
              return;
            }
            this.setState({ sendersList: data });
          });
      } else if (
        localStorage.getItem("USER_LEVEL") == 3 &&
        localStorage.getItem("ACCOUNT_TYPE") == 7
      ) {
        return;
      } else {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getDistPos",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            getType: "all",
            accountType: 17,
            distId:
              this.state.distId?.userId != undefined
                ? [this.state.distId?.userId]
                : [],
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else {
                this.setState(
                  {
                    sendersList: data["data"],
                  },
                  function() {}
                );
              }
            }
          });
      }
    } else if (this.state.tranType === "3") {
      if (
        localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18
      ) {
        return;
      } else if (
        localStorage.getItem("USER_LEVEL") == 1 ||
        localStorage.getItem("PERM_TYPE") == 5
      ) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getDistPos",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            getType: "all",
            accountType: 17,
            distId:
              this.state.distId?.userId != undefined
                ? [this.state.distId?.userId]
                : [],
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else {
                this.setState(
                  {
                    sendersList: data["data"],
                  },
                  function() {}
                );
              }
            }
          });
      }
    }
  };
  getReceivers = () => {
    if (this.state.tranType === "0") {
      if (
        localStorage.getItem("USER_LEVEL") == 3 &&
        localStorage.getItem("ACCOUNT_TYPE") == 7
      ) {
        return;
      } else {
        if (localStorage.getItem("USER_LEVEL") != 8) {
          fetch(window.API_PERM_USER, {
            method: "POST",
            body: JSON.stringify({
              type: "getDistPos",
              lang: localStorage.getItem("LANG"),
              userId: localStorage.getItem("USER_ID"),
              token: localStorage.getItem("USER_TOKEN"),
              getType: "all",
              accountType: 17,
              distId:
                this.state.distId?.userId != undefined
                  ? [this.state.distId?.userId]
                  : [],
            }),
            headers: {
              Accept: "application/json",
            },
          })
            .then(function(response) {
              return response.json();
            })
            .then((data) => {
              if (data !== "Nothing found") {
                if (data["logout"] == 1) {
                  localStorage.setItem("USER_ID", "");
                  localStorage.setItem("USER_TOKEN", "");
                  window.location.href = "/logout";
                } else {
                  this.setState(
                    {
                      receiverList: data["data"],
                    },
                    function() {}
                  );
                }
              }
            });
        }
      }
    } else if (this.state.tranType === "1") {
      if (
        localStorage.getItem("USER_LEVEL") == 1 ||
        localStorage.getItem("PERM_TYPE") == 5
      ) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getDistPos",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            getType: "all",
            accountType: 17,
            distId:
              this.state.distId?.userId != undefined
                ? [this.state.distId?.userId]
                : [],
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else {
                this.setState(
                  {
                    receiverList: data["data"],
                  },
                  function() {}
                );
              }
            }
          });
      } else if (
        localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18
      ) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getPerm",
            permType: 8,
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            lang: localStorage.getItem("LANG"),
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
              return;
            }
            this.setState({ receiverList: data });
          });
      }
    } else if (this.state.tranType === "3") {
      if (localStorage.getItem("USER_LEVEL") == 1) {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getAllDist",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            getType: "all",
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else {
                this.setState({ receiverList: data["data"] }, function() {});
              }
            }
          });
      }
    }
  };
  componentDidMount() {
    this.getPayState();
    this.getSenders();
    this.getReceivers();
    this.getTranType();
    if (localStorage.getItem("USER_LEVEL") == 1) {
      this.getAllDist();
    }
    if (localStorage.getItem("PERM_TYPE") == 5) {
      this.getPos();
    }
    this.handleSearch();
  }
  getAllDist = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDist",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ distributorList: data["data"] }, function() {});
          }
        }
      });
  };
  getSuperVisors() {
    if (localStorage.getItem("USER_LEVEL") == 1) {
      if (this.state.distId?.userId != undefined) return;

      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          getType: "all",
          accountType: 17,
          distId:
            this.state.distId?.userId != undefined
              ? [this.state.distId?.userId]
              : [],
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState(
                {
                  supervisorsList: data["data"],
                },
                function() {}
              );
            }
          }
        });
    } else if (
      localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
      localStorage.getItem("USER_LEVEL") == 18
    ) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPerm",
          permType: 8,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          lang: localStorage.getItem("LANG"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
            return;
          }
          this.setState({ supervisorsList: data });
        });
    }
  }
  getBillsState = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getBillsState",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        lang: localStorage.getItem("LANG"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
          return;
        }
        this.setState({ BillStates: data.billsType });
      });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.handleSearch();
    });
  };
  confirmBill2 = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-Succsess-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to confirm this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.confirmBillHandler2(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  acceptTrans = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-Succsess-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to confirm this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.acceptTransHandler(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  errorModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  noteModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3"></div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  confirmBillHandler2 = async (payId) => {
    try {
      this.setState({ loading: 1 });
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "acceptPayrequest",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          payId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }

      if (res.error == 1) {
        this.errorModal(res["errorMsg"]);
      } else {
        this.props.getSupervisorBalance();
        this.handleSearch();
        this.errorModal(
          res["errorMsg"].split("\\n").join("\n") || Strings.doneSucessfully
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false, loading: 0 });
    }
  };
  cancelBill = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.rejectBillHandler(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  rejectBillHandler = async (payId) => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "rejectPayRequest",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          payId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      if (res.error == 1) {
        this.errorModal(res["errorMsg"]);
      } else {
        this.handleSearch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  acceptTransHandler = async (payId) => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "acceptTrans",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          payId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      this.handleSearch();
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  deleteBill = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to delete this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.deleteBillHandler(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  deleteBillHandler = async (payId) => {
    try {
      this.setState({ loading: 1 });
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "deletePay",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          payId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      this.noteModal(res["errorMsg"]);
      this.setState({ loading: 0 }, () => {
        if (res.error == 1) {
          this.errorModal(res["errorMsg"]);
        } else {
          this.handleSearch();
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  render() {
    return (
      <div>
        <Row>
          <Col lg={12} className="main-color-1 inv-class-view-title mb-4">
            <div>
              {this.props.type ? Strings["Receivables"] : Strings["pay"]}
            </div>
            <hr className="inv-hr-view-2" />
          </Col>
        </Row>
        <Row className="page-filter-view">
          <Col
            xl={{ size: 5, order: 1 }}
            lg={{ size: 7, order: 1 }}
            md={{ size: 7, order: 1 }}
            sm={{ size: 10, order: 1 }}
            xs={{ size: 12, order: 1 }}
          >
            <AvForm ref={(c) => (this.form = c)}>
              <Row className="filter-form">
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="sentDateFrom"
                    type="date"
                    label={`${Strings["From Date"]} (${Strings["send"]})`}
                    value={this.state.sentDateFrom}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="sentDateTo"
                    type="date"
                    label={`${Strings["To Date"]} (${Strings["send"]})`}
                    value={this.state.sentDateTo}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                {/* <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="confirmDateFrom"
                    type="date"
                    label={`${Strings["From Date"]} (${Strings["receive"]})`}
                    value={this.state.confirmDateFrom}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="confirmDateTo"
                    type="date"
                    label={`${Strings["To Date"]} (${Strings["receive"]})`}
                    value={this.state.confirmDateTo}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col> */}
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    type="select"
                    name="payState"
                    // label={Strings["Invoice Type"] + " *"}
                    value={this.state.payState}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3 mt-2"
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Pay state"]}
                    </option>
                    {this.state.payStates?.map((data, index) => (
                      <option value={data?.key} key={index}>
                        {data?.value}
                      </option>
                    ))}
                  </AvField>
                </Col>

                {this.props.type && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <AvField
                      type="select"
                      name="tranType"
                      // label={Strings["Invoice Type"] + " *"}
                      value={this.state.tranType}
                      onChange={this.handleInputChange}
                      className="p-1 pl-3 mt-2"
                    >
                      <option
                        style={{
                          display: "none",
                        }}
                        value=""
                        selected="selected"
                      >
                        {Strings["Type Of Operation"]}
                      </option>
                      {this.state.tranTypes?.map((data, index) => (
                        <option value={data?.key} key={index}>
                          {data?.value}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                )}
                {localStorage.getItem("USER_LEVEL") == 1 && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="member-searchable-list mb-2 mt-2">
                      <Picky
                        value={this.state.distId}
                        options={this.state.distributorList}
                        onChange={this.handleChangeSelect.bind(this, "distId")}
                        open={false}
                        valueKey="userId"
                        labelKey="fname"
                        multiple={false}
                        includeSelectAll={false}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Distributor Name"]}
                        filterPlaceholder={Strings["Select Distributor Name"]}
                      />
                    </div>
                  </Col>
                )}
                {(localStorage.getItem("USER_LEVEL") == 1 ||
                  localStorage.getItem("PERM_TYPE") == 5) &&
                  !this.props.type && (
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="member-searchable-list mb-2">
                        <Picky
                          value={this.state.posId}
                          options={this.state.posList}
                          onChange={this.handleChangeSelect.bind(this, "posId")}
                          open={false}
                          valueKey="userId"
                          labelKey="fname"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          placeholder={Strings["POS"]}
                          filterPlaceholder={Strings["Select POS"]}
                          selectAllText={Strings["Select All"]}
                          allSelectedPlaceholder={Strings["%s selected"]}
                          manySelectedPlaceholder={Strings["%s selected"]}
                          clearFilterOnClose={true}
                        />
                      </div>
                    </Col>
                  )}

                {((this.state.tranType === "0" &&
                  !(
                    localStorage.getItem("USER_LEVEL") == 3 &&
                    localStorage.getItem("ACCOUNT_TYPE") == 7
                  )) ||
                  ((this.state.tranType === "1" ||
                    this.state.tranType === "3") &&
                    (localStorage.getItem("USER_LEVEL") == 1 ||
                      localStorage.getItem("PERM_TYPE") == 5))) && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="member-searchable-list mb-2 mt-2">
                      <Picky
                        value={this.state.senderId}
                        options={this.state.sendersList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "senderId"
                        )}
                        open={false}
                        valueKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "userId"
                            : "permId"
                        }
                        labelKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "fname"
                            : "name"
                        }
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Sender Name"]}
                        filterPlaceholder={Strings["Sender Name"]}
                      />
                    </div>
                  </Col>
                )}
                {((this.state.tranType === "0" &&
                  !(
                    localStorage.getItem("USER_LEVEL") == 3 &&
                    localStorage.getItem("ACCOUNT_TYPE") == 7
                  )) ||
                  ((this.state.tranType === "1" ||
                    this.state.tranType === "3") &&
                    (localStorage.getItem("USER_LEVEL") == 1 ||
                      localStorage.getItem("PERM_TYPE") == 5))) &&
                  localStorage.getItem("USER_LEVEL") != 8 && (
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="member-searchable-list mb-2 mt-2">
                        <Picky
                          value={this.state.receiverId}
                          options={this.state.receiverList}
                          onChange={this.handleChangeSelect.bind(
                            this,
                            "receiverId"
                          )}
                          open={false}
                          valueKey={
                            localStorage.getItem("USER_LEVEL") == 1 ||
                            localStorage.getItem("PERM_TYPE") == 5
                              ? "userId"
                              : "permId"
                          }
                          labelKey={
                            localStorage.getItem("USER_LEVEL") == 1 ||
                            localStorage.getItem("PERM_TYPE") == 5
                              ? "fname"
                              : "name"
                          }
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          placeholder={Strings["receiverName"]}
                          filterPlaceholder={Strings["receiverName"]}
                        />
                      </div>
                    </Col>
                  )}
              </Row>
            </AvForm>
          </Col>
          <Col
            xl={{ size: 7, order: 12 }}
            lg={{ size: 5, order: 12 }}
            md={{ size: 5, order: 12 }}
            sm={{ size: 2, order: 12 }}
            xs={{ size: 12, order: 12 }}
            className="text-right mb-3 "
          >
            <div className="d-flex flex-wrap flex-column align-items-end gap-3 w-100">
              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                localStorage.getItem("USER_LEVEL") == 18) &&
                !this.props.type && (
                  <button
                    variant="contained"
                    className="btn form-save-btn ml-1 mr-1 mb-2"
                    onClick={() => {
                      this.openSidebarModal(true);
                    }}
                  >
                    {Strings["pay"]}
                  </button>
                )}
              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                localStorage.getItem("USER_LEVEL") == 18) &&
                this.props.type && (
                  <button
                    variant="contained"
                    className="btn form-save-btn ml-1 mr-1 mb-2"
                    onClick={() => {
                      this.openSidebarModal(null);
                    }}
                  >
                    {localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                    localStorage.getItem("USER_LEVEL") == 18
                      ? Strings.tranDebit
                      : Strings["pay"]}
                  </button>
                )}
              <button
                className="btn page-action-btn ml-1 mb-2"
                onClick={(event) => this.exportHandler(event)}
              >
                {Strings["Export"]}
              </button>
              <button
                variant="contained"
                className="btn form-save-btn ml-1 mr-1 mb-2"
                onClick={this.handleSearch}
              >
                {Strings["Search"]}{" "}
                <SearchIcon className="font-size-15  main-color-2" />
              </button>

              <Col xs={12} lg={1} md={1} sm={12}>
                <Button
                  variant="contained"
                  type="button"
                  className="refresh-action-btn mb-2 mb-md-0"
                  onClick={this.handleRefresh}
                  title={Strings["Refresh"]}
                >
                  <i className="flaticon-refresh reset-filter-btn"></i>
                </Button>
              </Col>
              {this.state.status && (
                // <div className=" mt-3 finDistReport-square-view ">
                //   {this.state.status.totalCount && (
                //     <p className="task-square-div">
                //       {this.props.type
                //         ? Strings.TransferTotal
                //         : Strings["InvoicesQty"]}{" "}
                //       : {this.state.status.totalCount}
                //     </p>
                //   )}
                //   {this.state.status.totalSent && (
                //     <p className="task-square-div">
                //       {this.props.type
                //         ? Strings.totalDebtsSent
                //         : Strings["totalSent"]}{" "}
                //       : {this.state.status.totalSent}
                //     </p>
                //   )}
                //   {this.state.status.totalReceived && (
                //     <p className="task-square-div">
                //       {this.props.type
                //         ? Strings.totalDebtsReceived
                //         : Strings["totalReceived"]}{" "}
                //       : {this.state.status.totalReceived}
                //     </p>
                //   )}
                // </div>
                <div className="w-100 tooltipRequestStyle1 report-tooltip-span p-2 c-card-2">
                  <Row className="buttom-border-tool-tip ">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.type
                          ? Strings.TransferTotal
                          : Strings["paymentsQty"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.type
                        ? Strings.TransferTotal
                        : Strings["paymentsQty"]}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.type
                          ? Strings.totalDebtsSent
                          : Strings["total payments"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.type
                        ? Strings.totalDebtsSent
                        : Strings["total payments"]}
                    </Col>
                  </Row>
                  <Row className="table-body-row">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.totalCount}
                      className="overflow-text-length main-color-1 font-w-400"
                    >
                      {this.state.status.totalCount}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.totalSent}
                      className="overflow-text-length"
                    >
                      {this.state.status.totalSent}
                    </Col>
                  </Row>
                  {this.props.type && (
                    <>
                      <Row className="buttom-border-tool-tip ">
                        <Col
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          title={
                            this.props.type
                              ? Strings.totalDebtsReceived
                              : Strings["totalReceived"]
                          }
                          className="overflow-text-length"
                        >
                          {this.props.type
                            ? Strings.totalDebtsReceived
                            : Strings["totalReceived"]}
                        </Col>
                      </Row>
                      <Row className="table-body-row">
                        <Col
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          title={this.state.status.totalReceived}
                          className="overflow-text-length"
                        >
                          {this.state.status.totalReceived}
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}
        {this.state.loading == 0 &&
          this.state.data.length <= 0 &&
          this.state.errorMsg == "" && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </div>
            </Row>
          )}
        {this.state.loading == 1 && (
          <Row>
            <Col lg={12} style={{ marginTop: "7%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row d-none d-lg-flex d-md-flex">
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Tran ID"]}
                >
                  {Strings["Tran ID"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Amount"]}
                >
                  {Strings["Amount"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Sender Name"]}
                >
                  {Strings["Sender Name"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["receiverName"]}
                >
                  {Strings["receiverName"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["sentDate"]}
                >
                  {Strings["sentDate"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["confirmDate"]}
                >
                  {Strings["confirmDate"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Status"]}
                >
                  {Strings["Status"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Image"]}
                >
                  {Strings["Image"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Type Of Operation"]}
                >
                  {Strings["Type Of Operation"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Notes"]}
                >
                  {Strings["Notes"]}
                </Col>
                {/* {localStorage.getItem("USER_LEVEL") != 1 &&
                  localStorage.getItem("PERM_TYPE") != 5 && (
                    <Col
                      lg={1}
                      md={12}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Accept"]}
                    >
                      {Strings["Accept"]}
                    </Col>
                  )}
                {localStorage.getItem("USER_LEVEL") != 1 &&
                  localStorage.getItem("PERM_TYPE") != 5 && (
                    <Col
                      lg={1}
                      md={12}
                      sm={12}
                      xs={12}
                      className="overflow-text-length"
                      title={Strings["Reject"]}
                    >
                      {Strings["Reject"]}
                    </Col>
                  )} */}
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length text-right"
                  title={Strings["Action"]}
                >
                  {Strings["Action"]}
                </Col>
              </Row>
              {this.state.data.map((rowData, index) => {
                return (
                  <Row key={index} className="table-body-row">
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-2 order-md-1 order-lg-1"
                      title={Strings["Tran ID"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Tran ID"]} :{" "}
                      </span>
                      {rowData.payId}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-3 order-md-2 order-lg-2"
                      title={Strings["Amount"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Amount"]} :{" "}
                      </span>
                      {rowData.amount}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-4 order-md-3 order-lg-3"
                      title={Strings["Sender Name"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Sender Name"]} :{" "}
                      </span>
                      {rowData.senderName}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-5 order-md-4 order-lg-4"
                      title={Strings["receiverName"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["receiverName"]} :{" "}
                      </span>
                      {rowData.receiverName}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-6 order-md-5 order-lg-5"
                      title={Strings["sentDate"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["sentDate"]} :{" "}
                      </span>
                      {rowData.sentDate}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-7 order-md-6 order-lg-6"
                      title={Strings["confirmDate"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["confirmDate"]} :{" "}
                      </span>
                      {rowData.confirmDate}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-8 order-md-7 order-lg-7"
                      title={Strings["Status"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Status"]} :{" "}
                      </span>
                      <span
                        className={`text-bold 
                          ${
                            rowData.status == "1"
                              ? "profit-color"
                              : rowData.status === "0"
                              ? "warning-color"
                              : rowData.status == "-1"
                              ? "delete-color"
                              : ""
                          }`}
                      >
                        {rowData.statusTitle}
                        {!this.props.type && rowData.createrName != "" && (
                          <Tooltip
                            title={rowData.createrName}
                            visible={rowData.payId == this.state.tooltipDisplay}
                            animation="zoom"
                            onVisibleChange={(event) =>
                              this.setState({
                                tooltipDisplay:
                                  rowData.payId == this.state.tooltipDisplay
                                    ? null
                                    : rowData.payId,
                              })
                            }
                            trigger="click"
                            overlay={
                              <span className="report-tooltip-span">
                                <p className="mb-0 mr-1 tooltipRequestStyle break-words">
                                  {`${Strings["Invoice Creator"]}: ${rowData.createrName}`}
                                </p>
                              </span>
                            }
                          >
                            <InfoOutlinedIcon
                              title={rowData.createrName}
                              className="reset-filter-btn mt-1 m-1 hover-green"
                            />
                          </Tooltip>
                        )}
                      </span>
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-9 order-md-8 order-lg-8"
                      title={Strings["Image"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Image"]} :{" "}
                      </span>
                      {rowData.paymentImg ? (
                        <a href={rowData.paymentImg} target="_blank">
                          {Strings["clickHere"]}
                        </a>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-9 order-md-8 order-lg-8"
                      title={Strings["Type Of Operation"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Type Of Operation"]} :{" "}
                      </span>
                      {rowData.tranTypeTitle}
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      className="overflow-text-length order-10 order-md-9 order-lg-9"
                      title={Strings["Notes"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Notes"]} :{" "}
                      </span>
                      {rowData.notes?.length > 0 && (
                        <a
                          className="link-color-sample"
                          onClick={(e) => {
                            e.preventDefault();
                            this.noteModal(rowData.notes);
                          }}
                        >
                          {Strings["View Details"]}
                        </a>
                      )}
                    </Col>
                    {localStorage.getItem("USER_LEVEL") != 1 &&
                      localStorage.getItem("PERM_TYPE") != 5 &&
                      rowData.status == "0" && (
                        <Col
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          className="overflow-text-length d-block d-lg-none d-md-none order-1 order-md-10 order-lg-10"
                          title={Strings["Image"]}
                        >
                          {console.log(
                            "rowData.senderId",
                            rowData.senderId,
                            localStorage.getItem("USER_ID")
                          )}
                          {rowData.senderId !=
                            localStorage.getItem("USER_ID") && (
                            <a
                              className="link-color-sample"
                              onClick={(e) => {
                                e.preventDefault();
                                this.confirmBill2(e, rowData.payId);
                              }}
                            >
                              {Strings["Accept"]}
                            </a>
                          )}
                        </Col>
                      )}
                    {localStorage.getItem("USER_LEVEL") != 1 &&
                      localStorage.getItem("PERM_TYPE") != 5 &&
                      rowData.status == "0" && (
                        <Col
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          className="overflow-text-length d-block d-lg-none d-md-none order-12 order-md-11 order-lg-11"
                          title={Strings["Reject"]}
                        >
                          {rowData.senderId !=
                            localStorage.getItem("USER_ID") && (
                            <a
                              className="link-color-sample"
                              onClick={(e) => {
                                e.preventDefault();
                                this.cancelBill(e, rowData.payId);
                              }}
                            >
                              {Strings["Reject"]}
                            </a>
                          )}
                        </Col>
                      )}
                    {(localStorage.getItem("PERM_TYPE") == 5 ||
                      localStorage.getItem("USER_LEVEL") == 1) &&
                      !this.props.type && (
                        <Col
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          className="overflow-text-length d-block d-lg-none d-md-none order-14 order-md-13 order-lg-13"
                          title={Strings["Cancel"]}
                        >
                          <a
                            className="link-color-sample"
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteBill(e, rowData.payId);
                            }}
                          >
                            {Strings["Cancel"]}
                          </a>
                        </Col>
                      )}

                    {!this.props.type && (
                      <Col
                        lg={1}
                        md={1}
                        sm={12}
                        xs={12}
                        className="overflow-text-length order-13 order-md-12 order-lg-12 d-flex d-md-none d-lg-none"
                        title={Strings["details"]}
                      >
                        <span className="d-inline d-lg-none d-md-none text-bold">
                          {Strings["details"]} :{" "}
                        </span>
                        {
                          <a
                            className="link-color-sample"
                            onClick={(e) => {
                              e.preventDefault();
                              this.cancelBill(e, rowData.payId);
                            }}
                          >
                            {Strings["details"]}
                          </a>
                        }
                      </Col>
                    )}
                    {
                      <Col
                        lg={1}
                        md={12}
                        sm={1}
                        xs={12}
                        className={
                          "action-icon-view actionDropdownList actionDropdownListPadding-0 order-1 order-md-12 order-lg-12 d-none d-lg-flex d-md-flex flex-row-reverse"
                        }
                      >
                        <Dropdown
                          isOpen={
                            this.state.openHeader ==
                            `${index}-${this.state.activePage}`
                              ? true
                              : false
                          }
                          className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
                        >
                          <DropdownToggle
                            tag="a"
                            className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                            caret
                          >
                            <MoreVertIcon
                              className="menu-dots-vertical actionDropdownList"
                              onClick={(event) =>
                                this.openHeaderMenu(
                                  event,
                                  `${index}-${this.state.activePage}`
                                )
                              }
                            />
                          </DropdownToggle>
                          <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList ">
                            <div
                              className="actionDropdownList"
                              style={{
                                maxHeight: "250px",
                                overflowY: "scroll",
                              }}
                            >
                              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                                localStorage.getItem("USER_LEVEL") == 18) &&
                                rowData.status == "0" &&
                                rowData.senderId !=
                                  localStorage.getItem("USER_ID") && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.cancelBill(event, rowData.payId);
                                    }}
                                  >
                                    {Strings["Reject"]}
                                  </DropdownItem>
                                )}
                              {(localStorage.getItem("PERM_TYPE") == 5 ||
                                localStorage.getItem("USER_LEVEL") == 1) &&
                                !this.props.type && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.deleteBill(event, rowData.payId);
                                    }}
                                  >
                                    {Strings["Cancel"]}
                                  </DropdownItem>
                                )}
                              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                                localStorage.getItem("USER_LEVEL") == 18) &&
                                rowData.status == "0" &&
                                rowData.senderId !=
                                  localStorage.getItem("USER_ID") && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.confirmBill2(event, rowData.payId);
                                    }}
                                  >
                                    {Strings["Accept"]}
                                  </DropdownItem>
                                )}
                              {!this.props.type && (
                                <DropdownItem
                                  className="actionDropdownList"
                                  onClick={(event) => {
                                    this.openSidebarModal2(rowData);
                                  }}
                                >
                                  {Strings["details"]}
                                </DropdownItem>
                              )}
                            </div>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    }
                  </Row>
                );
              })}
              {this.state.loading == 0 && this.state.data?.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className={
            this.state.actionType == "notification"
              ? "sidebar-modal-large-view"
              : "sidebar-modal-view"
          }
        >
          {this.state.openleftModal && (
            <SendPayRequestForm
              getSupervisorBalance={this.props.getSupervisorBalance}
              close={this.closeSidebarModal}
              type={this.props.type}
            />
          )}
        </Drawer>
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openModal}
          className={"sidebar-modal-large-view"}
        >
          {this.state.openModal && (
            <PayDetails
              {...this.state.modalData}
              close={this.closeSidebarModal2}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
