import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Picky } from "react-picky";
import { translations } from "../../../../translate.js";
import { CircularProgress } from "@material-ui/core";

const Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
let today = new Date();
let currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);
export default function PosLimits({
  posData,
  distId,
  permType,
  handleCloseSideBar,
  pageView,
  isAdmin
}) {
  console.log({ pageView });

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [debit, setDebit] = useState(0);
  const [startSave, setStartSave] = useState(false);
  const [maxDebtBillsNum, setMaxDebtBillsNum] = useState(0);
  const [maxBillValue, setMaxBillValue] = useState(0);
  const [maxCashInHand, setMaxCashInHand] = useState(0);
  const [maxCashDebit, setMaxCashDebit] = useState(0);
  const [submitError, setSubmitError] = useState("");
  const [error, setError] = useState("");
  const handleInputChange = (event, setState) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSubmitError("");
    setState(value);
    // setState({
    //   ...state,
    //   [name]: value,
    // });
  };
  
  const getLimitsData = async () => {
    try {
      setLoading(true);
      const body = JSON.stringify({
        type: "getUserLimitsInfoByuserId",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        id: parseInt(posData.userId),
      });
      const response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body,
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else if (data["error"] == 1) {
          setError(data["errorMsg"]);
        } else {
          setDebit(data["result"].debit || "0");
          setMaxDebtBillsNum(data["result"].maxDebtBillsNum || "0");
          setMaxBillValue(data["result"].maxBillValue || "0");
          setMaxCashInHand(data["result"].maxCashInHand || "0");
          setMaxCashDebit(data["result"].maxCashDebit || "0");
          setState({
            ...state,
            debit: data["result"].debit || "0",
            maxCashInHand: data["result"].maxCashInHand || "0",
            maxCashDebit: data["result"].maxCashDebit || "0",
            maxBillValue: data["result"].maxBillValue || "0",
            maxDebtBillsNum: data["result"].maxDebtBillsNum || "0",
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLimitsData();
  }, []);
  const handleValidSubmit = async () => {
    try {
      setStartSave(true);
      const body = JSON.stringify({
        type: "setUserLimits",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        id: parseInt(posData.userId),
        maxCashDebit: Number(maxCashDebit),
        maxCashInHand: Number(maxCashInHand),
        maxBillValue: Number(maxBillValue),
        maxDebtBillsNum: Number(maxDebtBillsNum),
      });
      const response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body,
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
        } else if (data["error"] == 1) {
          setSubmitError(data["errorMsg"]);
        } else {
          handleCloseSideBar();
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setStartSave(false);
    }
  };
  return (
    <div className="view-modal-style">
      <div className="sidebar-header-view">
        <Row>
          <Col md={10} xs={10} lg={10} sm={10}>
            <div className="p-0 main-color-1  sidebar-header-title">
              {`${Strings["POS Limits"]} : (${posData["fname"]} ${posData["lname"]})`}
            </div>
          </Col>
          <Col md={2} xs={2} lg={2} sm={2} className="text-right">
            <HighlightOffIcon
              onClick={handleCloseSideBar}
              className="modal-close-icon"
            />
          </Col>
        </Row>
      </div>
      <div className="sidebar-body-view">
        {console.log("state.maxDebtBillsNum", state.maxDebtBillsNum)}
        {loading && (
          <Row>
            <Col md={12} style={{ marginTop: "15%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {!loading && (
          <AvForm onValidSubmit={handleValidSubmit}>
            {pageView == "1" && (
              <AvField
                name="debit"
                type="text"
                disabled={true}
                label={Strings["debit"] + " *"}
                value={debit}
                onChange={(e) => {
                  handleInputChange(e, setDebit);
                }}
                errorMessage={Strings["This field is required"]}
              />
            )}

            <AvField
              name="maxDebtBillsNum"
              type="number"
              label={Strings["maxDebtBillsNum"] + " *"}
              value={maxDebtBillsNum}
              onChange={(e) => {
                handleInputChange(e, setMaxDebtBillsNum);
              }}
              errorMessage={Strings["maxDebtBillsNum"]}
              validate={{
                required: {
                  value: true,
                  errorMessage: Strings["This field is required"],
                },
                min: { value: 1, errorMessage: Strings.invalidNumber },
              }}
            />
            <AvField
              name="maxBillValue"
              type="number"
              label={Strings["maxBillValue"] + " *"}
              value={maxBillValue}
              onChange={(e) => {
                handleInputChange(e, setMaxBillValue);
              }}
              errorMessage={Strings["This field is required"]}
              validate={{
                required: {
                  value: true,
                  errorMessage: Strings["This field is required"],
                },
                min: { value: 1, errorMessage: Strings.invalidNumber },
              }}
            />
            {pageView == "1" && (
              <AvField
                name="maxCashInHand"
                type="number"
                label={Strings["maxCashInHand"] + " *"}
                value={maxCashInHand}
                onChange={(e) => {
                  handleInputChange(e, setMaxCashInHand);
                }}
                errorMessage={Strings["This field is required"]}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Strings["This field is required"],
                  },
                  min: { value: 0, errorMessage: Strings.invalidNumber },
                }}
              />
            )}
            {pageView == "1" && (
              <AvField
                name="maxCashDebit"
                type="number"
                label={Strings["maxCashDebit"] + " *"}
                value={maxCashDebit}
                onChange={(e) => {
                  handleInputChange(e, setMaxCashDebit);
                }}
                errorMessage={Strings["This field is required"]}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Strings["This field is required"],
                  },
                  min: { value: 0, errorMessage: Strings.invalidNumber },
                }}
              />
            )}

            <div className="text-right mt-4">
              {!startSave ? (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              ) : (
                <button type="button" className="btn form-save-btn">
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              )}

              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>
            {submitError && (
              <div className="error-text-black mt-3">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {submitError}
              </div>
            )}
          </AvForm>
        )}
      </div>
    </div>
  );
}
