// import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { useDispatch, useSelector } from "react-redux";

function Logout(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    logoutHandler();
  }, []);

  const logoutHandler = () => {
    let lang = localStorage.getItem("LANG");
    const isSaved = localStorage.getItem("SAVED") === "1";
    const savedCredentials = localStorage.getItem("CREDENTIALS");
    localStorage.clear();
    localStorage.setItem("LANG", lang);
    localStorage.setItem("SAVED", isSaved ? "1" : "0");
    localStorage.setItem(
      "CREDENTIALS",
      savedCredentials ? savedCredentials : ""
    );
    localStorage.setItem("USER_ID", "");
    localStorage.setItem("USER_EMAIL", "");
    localStorage.setItem("USER_FULL_NAME", "");
    localStorage.setItem("USER_BALANCE", "");
    localStorage.setItem("POS_MGR_EMP", 0);
    localStorage.setItem("PERM_TYPE", "");
    localStorage.setItem("ACCOUNT_TYPE", "");
    localStorage.setItem("USER_LEVEL", "");
    localStorage.setItem("PERM_TYPE_PARENT", "");
    localStorage.setItem("IS_TAXDIST", "");
    localStorage.setItem("USER_BALANCE_FROM", "");
  };

  // render() {
  const { hasAuthToken } = props;

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  // }
}

// class Logout extends Component {
//   componentDidMount() {
//     this.props.logout();
//     this.logoutHandler()
//   }

//   logoutHandler = () => {
//     localStorage.setItem('USER_ID', '');
//     localStorage.setItem('USER_EMAIL', '');
//     localStorage.setItem('USER_FULL_NAME', '');
//     localStorage.setItem('USER_BALANCE', '');
//     localStorage.setItem('POS_MGR_EMP', 0);
//     localStorage.setItem('PERM_TYPE', '');
//     localStorage.setItem('ACCOUNT_TYPE', '');
//     localStorage.setItem('USER_LEVEL', '');
//     localStorage.setItem('PERM_TYPE_PARENT', '');
//     localStorage.setItem('IS_TAXDIST', '');
//   }

//   render() {
//     const { hasAuthToken } = this.props;

//     return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
//   }
// }

export default Logout;
